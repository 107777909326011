import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../utils/api_helper';
import { categoryIcons, getParentbyCategory } from '../../utils';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsEyeFill } from 'react-icons/bs';
import { Button, Modal, Table } from 'react-bootstrap';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { convertToInternationalCurrencySystem } from '../../utils';

const ProductItem = ({ portfolio }) => {
  const [attachments, setAttachments] = useState({});
  const [coverImage, setCoverImage] = useState();
  const [link, setLink] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [company, setCompany] = useState(false);
  const [quickView, setQuickView] = useState(false);
  const [des, setDesc] = useState('');
  const [cur, setCur] = useState(portfolio.currency);

  useEffect(() => {
    async function getLogo(url) {
      get('/uploads/' + url)
        .then((resp) => {
          setCompanyLogo(
            process.env.REACT_APP_SAARC_API + '/' + resp.path.substring(11).replace(/\\/g, '/')
          );
        })
        .catch((err) => {
          setCompanyLogo('/images/company/default-banner.png');
          console.error('error getting logo', err);
        });
    }
    
    if (portfolio && portfolio.Company && portfolio.Company.logo && portfolio.Company.logo !== '') {
      getLogo(portfolio.Company.logo);
    } else if (portfolio && portfolio.logo) {
      getLogo(portfolio.logo);
    } else {
      setCompanyLogo('/images/company/default-banner.png');
    }
  }, [portfolio]);

  useEffect(() => {
    if (portfolio && portfolio.name) {
      setCompany(true);
      setLink('/company-details/');
    } else {
      setCompany(false);
      setLink('/product-details/');
    }
    async function getAttachment() {
      if (portfolio && portfolio.name && portfolio.cover_image) {
        if (portfolio.cover_image !== '') {
          get('/uploads/' + portfolio.cover_image)
            .then((resp) => {
              setCoverImage(resp.path.substring(11).replace(/\\/g, '/'));
            })
            .catch((err) => console.error('Error getting cover image', err));
        }
      }
    }
    getAttachment();
  }, []);

  const getCatIcon = (category) => {
    if (!category) return;
    let cat = categoryIcons.find((item) => item.label === category);
    if (cat) {
      const Icon = cat.icon;
      return <Icon size={30} color="black" className="company-icons" />;
    }
  };

  const showQuickView = () => {
    setQuickView(true);
  };

  const handleClose = () => {
    setQuickView(false);
  };

  useEffect(() => {
    let dsc = portfolio.description;
    async function getData() {
      if (portfolio.description) {
        setDesc(stateToHTML(convertFromRaw(JSON.parse(dsc))));
      }
    }
    getData();
  }, [portfolio.description]);

  useEffect(() => {
    async function getUploads() {
      if (portfolio && portfolio.title && portfolio.attachment) {
        const splitAttachments = portfolio.attachment.split(';');
        await get('/uploads')
          .then((resp) => {
            setAttachments(resp.filter((x) => splitAttachments.includes(x.id)));
          })
          .catch((err) => {
            console.error('error getting uploads', err);
          });
      }
    }
    getUploads();
  }, [portfolio.attachment]);

  const createURL = (path) => {
    return path.substring(12).replace(/\\/g, '/').slice(0, -1);
  };

  return (
    <React.Fragment>
      <Modal show={quickView} onHide={handleClose}>
        <Modal.Body>
          <h4 className="mb-0 ">{portfolio.name || portfolio.title}</h4>
          <p className="product-tagline mb-3">{portfolio.tagline}</p>
          <p className="no-margin no-padding product-tagline mb-2 color-midgray">
            {getParentbyCategory(portfolio.category)} > {portfolio.category}
          </p>
          <div className="row">
            <div className="col-lg-4">
              {portfolio.attachment && attachments && Object.keys(attachments).length > 0 ? (
                <img
                  src={process.env.REACT_APP_SAARC_IMAGE_API + '/' + createURL(attachments[0].path)}
                  alt="Product Image"
                  width="200"
                  height="200"
                />
              ) : null}
              <div className=" d-grid gap-2 text-center">
                <Link
                  to={process.env.PUBLIC_URL + link + portfolio.id}
                  className="btn btn-primary btn-lg mt-5 "
                >
                  View Full Details
                </Link>
              </div>
            </div>
            <div className="quick-view col-lg-8">
              <h5>
                <GiHamburgerMenu color="#666666" /> {'   '}
                Product Description
              </h5>{' '}
              <div
                className="company_desc mt-0"
                dangerouslySetInnerHTML={{
                  __html: des.substring(0, 250) + '...',
                }}
              />
              <h5>
                <GiHamburgerMenu color="#666666" /> {'   '}
                Product Details
              </h5>
              <Table className="table table-bordered body-font ">
                <tbody>
                  <tr>
                    <td>Price:</td>
                    <td>
                      {portfolio?.hasPrice
                        ? cur?.toUpperCase() +
                          '   ' +
                          portfolio?.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 'Ask for Price'}
                    </td>
                  </tr>
                  {portfolio.moq ? (
                    <tr>
                      <td>Minimum Order Quantity:</td>
                      <td>{portfolio.moq}</td>
                    </tr>
                  ) : null}{' '}
                  {portfolio && portfolio.Company && portfolio.Company.company_country ? (
                    <tr>
                      <td>Country:</td>
                      <td> {portfolio.Company.company_country}</td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="rwt-card">
        <div className="inner">
          <div className="thumbnail">
            <figure className="card-image">
              <Link to={process.env.PUBLIC_URL + link + portfolio.id}>
                {portfolio &&
                portfolio.title &&
                portfolio.attachment &&
                Object.keys(attachments).length > 0 &&
                attachments.sort((a, b) =>
                  createURL(a.path).split('/').pop() > createURL(b.path).split('/').pop() ? 1 : -1
                ) ? (
                  <>
                    <figure class={portfolio.featured ? 'img tag tag-featured' : ''}>
                      <img
                        src={
                          process.env.REACT_APP_SAARC_IMAGE_API +
                          '/' +
                          createURL(attachments[0].path)
                        }
                        alt="Product Image"
                      />
                    </figure>
                  </>
                ) : (
                  <>
                    {portfolio && portfolio.name && coverImage ? (
                      <>
                        <img
                          alt="Cover Image"
                          src={process.env.REACT_APP_SAARC_IMAGE_API + '/' + coverImage}
                        />
                      </>
                    ) : (
                      <img
                        src={'/images/company/default-cover.jpg'}
                        alt="Default Cover"
                        width="100%"
                      />
                    )}
                  </>
                )}
              </Link>
            </figure>
            <Link to={process.env.PUBLIC_URL + link + portfolio.id} className="rwt-overlay" />
          </div>
          <div className="lf-item-container">
            <div className="lf-item-info-2">
              <span
                className="lf-avatar"
                style={{
                  backgroundImage: `url('${companyLogo}')`,
                }}
              />
              <h6
                title={portfolio.name || portfolio.title}
                className="title tagline-overflow search-font mb-0"
              >
                <Link to={process.env.PUBLIC_URL + link + portfolio.id}>
                  {portfolio.title || portfolio.name}
                </Link>{' '}
                {portfolio && portfolio.title ? (
                  <a className="cursor-pointer" onClick={showQuickView}>
                    <BsEyeFill />
                  </a>
                ) : null}
              </h6>
              <div
                title={portfolio.tagline}
                className="tagline-overflow subtitle b2 text-capitalize tagline-font cursor-pointer"
              >
                {portfolio.tagline || <br />}
              </div>
              {!company ? (
                <>
                  <div>
                    <p className="prod-price">
                      {portfolio.moq ? (
                        <>
                          <span>Minimum Order: </span>

                          <span className="font-weight-bold">
                            {portfolio.moq} {portfolio.moq >= 0 ? 'Piece(s)' : null}
                          </span>
                        </>
                      ) : (
                        'Minimum Order: Contact Seller'
                      )}
                    </p>
                  </div>
                </>
              ) : null}

              <div>
                <span>
                  <p className="lf-contact">
                    {portfolio.Company && portfolio.Company.company_country
                      ? portfolio.Company.company_country
                      : null}
                  </p>
                </span>
              </div>
            </div>
            <div className="listing-details ">
              <ul className="pl-3">
                <li className="category-name ">
                  {portfolio &&
                  portfolio.title &&
                  portfolio.category !== null &&
                  portfolio.category !== '' ? (
                    <Link to={'/search?s=' + portfolio.category.replace(' ', '+')}>
                      <span className="cat-icon">
                        {getCatIcon(getParentbyCategory(portfolio.category))}
                      </span>
                      <span title={portfolio.category} className="align-middle text-capitalize ">
                        {portfolio.category}
                      </span>
                    </Link>
                  ) : (
                    <>
                      {portfolio &&
                      portfolio.name &&
                      portfolio.category !== null &&
                      portfolio.category !== '' ? (
                        <Link to={'/search?s=' + portfolio.category.replace(' ', '+')}>
                          <span className="cat-icon">
                            {getCatIcon(getParentbyCategory(portfolio.industry))}
                          </span>
                          <span
                            title={portfolio.industry}
                            className="align-middle text-capitalize "
                          >
                            {portfolio.industry}
                          </span>
                        </Link>
                      ) : null}
                    </>
                  )}
                </li>

                <li className="mr-3 float-right price">
                  <span className="   font-weight-bold color-black ">
                    {portfolio.price ? (
                      localStorage.getItem('cur') +
                      ' ' +
                      convertToInternationalCurrencySystem(portfolio.price)
                    ) : (
                      <>
                        {/*If there is a product show price and if there is company show nothing*/}
                        {portfolio.title ? (
                          <Link
                            className="color-black"
                            to={'/product-details/' + portfolio.id + '?id=query'}
                          >
                            Ask for price
                          </Link>
                        ) : null}
                      </>
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ProductItem;
