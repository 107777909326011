import React, { useEffect, useState } from 'react';
import * as api from '../utils/api_helper';
import { getAllUsers, getUserId } from '../utils';
import { Launcher } from 'react-chat-window';
import ChatWindow from 'react-chat-window/lib/components/ChatWindow';

const NewMessage = (props) => {
  const [newMessageModal, setNewMessageModal] = useState(!!props.username);
  const [allUsers, setAllUsers] = useState('');
  const [refetch, setRefetch] = useState(0);
  const [errorMsg, setErrorMsg] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [toUser, setToUser] = useState('');
  const userId = getUserId();
  const handleClose = () => {
    setIsOpen(false);
    props.setNewMessage(false);
  };
  useEffect(() => {
    const getChatMessages = async () => {
      return new Promise((resolve, reject) => {
        api
          .get('/messages/all/' + props.username)
          .then((resp) => {
            resp.map((item) => {
              delete item['id'];
              if (item.fromId === userId) {
                item.author = 'me';
              } else {
                item.author = 'them';
              }
              item.data = { text: item.text };
              item.type = 'text';
              delete item['toId'];
              delete item['fromId'];
              delete item['createdAt'];
              delete item['updatedAt'];
              delete item['text'];
            });
            setMessages(resp);
            resolve(resp);
          })
          .catch((error) => reject(error));
      });
    };
    getChatMessages();
  }, [refetch]);
  useEffect(() => {
    async function getUsers() {
      await getAllUsers().then((resp) => {
        setAllUsers(resp);
        if (props.username) {
          let temp = resp.find((x) => x.label === props.username);
          if (temp) {
            // setCreateMessage({ ...createMessage, selectedUser: temp.value });
            setToUser(temp.value);
          }
        }
      });
    }
    getUsers();
  }, []);
  const handleSubmit = (message) => {
    console.log('message on handleSubmit',message)
    if (!message.data.text) return;
    message.data.selectedUser = toUser;
    message.data.messageText = message.data.text;
    handlingSend(message.data);
  };
  const sendMessage = async ({ selectedUser: toId, messageText: text }) => {
    console.log(toId);
    return new Promise((resolve, reject) => {
      api
        .post('/messages', { toId, text })
        .then((resp) => resolve(resp))
        .catch((error) => reject(error));
    });
  };

  const handlingSend = async (message) => {
    console.log(message);
    await sendMessage(message)
      .then((resp) => {
        console.log('Message sent successfully');
        console.log(resp);
        onUpdate();
      })
      .catch((e) => {
        setErrorMsg(e.response.data.message);
        console.error('Error sending message:', e);
        onUpdate();
      });
  };
  const onUpdate = () => {
    setRefetch(refetch + 1);
    // setTimeout(() => setErrorMsg(''), 3000);
  };
  function toggle_new_message() {
    setNewMessageModal(!newMessageModal);
    props.setNewMessage(false);
  }

  return (
    <>
      {isOpen ? (
        <>
          <ChatWindow
            class="react-chat"
            agentProfile={{
              teamName: props.companyName || props.username,
              // imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
            }}
            // onMessageWasSent={handleSubmit}
            messageList={messages}
            showEmoji={false}
            onFilesSelected={false}
            onClose={handleClose}
            isOpen={true}
            onUserInputSubmit={handleSubmit}
          />
          {/*<Launcher*/}
          {/*  agentProfile={{*/}
          {/*    teamName: props.companyName || props.username,*/}
          {/*    // imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',*/}
          {/*  }}*/}
          {/*  onMessageWasSent={handleSubmit}*/}
          {/*  messageList={messages}*/}
          {/*  showEmoji={false}*/}
          {/*  onFilesSelected={false}*/}
          {/*  onClose={handleClose}*/}
          {/*/>*/}
        </>
      ) : null}

      {/*<Modal*/}
      {/*  animation={false}*/}
      {/*  show={newMessageModal}*/}
      {/*  onHide={toggle_new_message}*/}
      {/*  size="sm"*/}
      {/*  className="message-modal"*/}
      {/*>*/}
      {/*  <Modal.Header closeButton>*/}
      {/*    <InputGroup className="mb-2">*/}
      {/*      <InputGroup.Prepend>*/}
      {/*        <InputGroup.Text size="lg">@</InputGroup.Text>*/}
      {/*      </InputGroup.Prepend>*/}
      {/*      <Form.Control*/}
      {/*        size="lg"*/}
      {/*        id="inlineFormInputGroup"*/}
      {/*        value={props.companyName || props.username}*/}
      {/*        placeholder="Username"*/}
      {/*        disabled*/}
      {/*      />*/}
      {/*    </InputGroup>*/}
      {/*  </Modal.Header>*/}
      {/*  <Modal.Body>*/}
      {/*    <Form>*/}
      {/*      {messages.map((message) => {*/}
      {/*        return <div>{message.text}</div>;*/}
      {/*      })}*/}
      {/*      <div className="mb-3">*/}
      {/*        <Form.Control*/}
      {/*          style={{ height: '100px' }}*/}
      {/*          as="textarea"*/}
      {/*          size="lg"*/}
      {/*          id="message-text"*/}
      {/*          placeholder="Write a message"*/}
      {/*          name="messageText"*/}
      {/*          type="textarea"*/}
      {/*          onChange={handleTextChange}*/}
      {/*          required*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </Form>*/}
      {/*  </Modal.Body>*/}
      {/*  <Modal.Footer>*/}
      {/*    <Button size="lg" variant="primary" type="button" onClick={handleSubmit}>*/}
      {/*      Send message*/}
      {/*    </Button>*/}
      {/*  </Modal.Footer>*/}
      {/*</Modal>*/}
    </>
  );
};
export default NewMessage;
