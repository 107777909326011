import React, { useEffect, useState } from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import CompanyPage from '../components/blog/CompanyPage';
import { get } from '../utils/api_helper';
import Error from './Error';
import { COMPANY_STATUS, getUserId, getUserRole, USER_ROLE } from '../utils';

const CompanyDetails = (props) => {
  const companyID = props.match.params.id;
  const preview = props.match.params.preview;
  const [companyData, setcompanyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const loop = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const userRole = getUserRole();
  const userId = getUserId();

  useEffect(() => {
    setLoading(true);
    let url = '';
    if (preview === 'preview') url = '/previewcompanies/';
    else if (preview === 'update') url = '/tempcompanies/';
    else url = /companies/;
    async function getCompanies() {
      await get(url + companyID)
        .then((resp) => {
          setcompanyData(resp);
          setLoading(false);
        })
        .catch((err) => {
          console.error('error getting company data', err);
          setLoading(false);
        });
    }
    getCompanies();
  }, []);

  return loading ? (
    <div className="spinner-custom centered custom-size-spinner" role="status">
      {loop.map((x) => (
        <span className="sr-only-custom" />
      ))}
    </div>
  ) : (
    <>
      {(companyData.status === COMPANY_STATUS.APPROVED && companyData.state === true) ||
      (companyData && (userRole === USER_ROLE.ADMIN || userId === companyData.UserId)) ? (
        <>
          {/* <SEO title={companyData.name} description={`Find hundreds of products from ${companyData.description} at SAARC Bazaar. We are here to help with the barriers. Shop now!`}/> */}
          <Layout>
            <CompanyPage companyData={companyData} />
          </Layout>
        </>
      ) : (
        <>
          <SEO title={'404 Not Found'} />
          <Error />
        </>
      )}
    </>
  );
};

export default CompanyDetails;
