import React, { useEffect, useState } from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import { AiFillCheckCircle } from 'react-icons/all';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import * as api from '../utils/api_helper';
import NewMessage from '../elements/NewMessage';
import { getUserId, getUserName } from '../utils';
// import NewMessage from '../elements/NewMessage';
import { isLoggedIn } from '../utils';
import {get} from "../utils/api_helper";
export default function OrderSuccess(props) {
  const userId = getUserId();
  const [data, setData] = useState([]);
    const name = getUserName();
    const [companiesData, setCompaniesData] = useState([])
  // const [companyName, setCompanyName] = useState('');
  const loggedIn = isLoggedIn();
  const [newMessage, setNewMessage] = useState(false);
  const[messageData,setMessageData] = useState({
      companyName:'',
      username:'',
      messages:'',
      newMessages:''

  })

    const openMessageBoxHandler = (supplier) => {
      setNewMessage(true);
      console.log("open box")
      setMessageData({
          companyName: supplier,
          username: companiesData.find((company) => company.name === supplier)?.User?.username,
          messages:newMessage
      })
        console.log("username boxxxx")


    }


  useEffect(() => {
    async function getCompanies() {
      api
          .get('companies/')
          .then((resp) => {
            setCompaniesData(resp);
          })
          .catch((err) => {
            console.error('Error product not found', err);
          });
    }
    getCompanies();
  }, []);

  useEffect(() => {
    api
      .get('/orders/user/' + userId, { params: { limit: 50 } })
      .then((response) => {
        setData(response);
        console.log(response, 'successs response');
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  // const chatboxHandler = (supplier_name) => {
  //   setNewMessage(true);
  //   setCompanyName(supplier_name);
  //   console.log('This is supplier name', supplier_name);
  // };
  return (
    <>
      <SEO title="Order Success| SAARC Bazaar" />

        {newMessage===true?
            <NewMessage
                companyName={messageData.companyName}
                username={'Sohaib Shahbaz'}
                newMessage={messageData.messages}
                setNewMessage={setNewMessage}
            />: null}

      <Layout>
        <div className="main-content">
          {/* Start Events Area  */}

          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 mt_dec--30">
                <div className="static-page">
                  <div className="text-center mb-5">
                    <AiFillCheckCircle size={100} color="green" />
                    <h4 className="mt-3 mb-5">Thank you for your purchase</h4>
                  </div>

                  <div>
                    {data &&
                      data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <>
                           <div className=" border-bottom selectall d-flex justify-content-between  ">
                              <div>
                                <a
                                  className="text-info"
                                  style={{ fontSize: '1.7' }}
                                  href={
                                    item.supplier_name
                                      ? process.env.REACT_APP_SAARC_DEFAULT_URL +
                                        '/company-details/' +
                                        item.supplier_id
                                      : ''
                                  }
                                  target="_blank"
                                >
                                  {/*{data && data.supplier_name ? data.supplier_name[index] : ''}*/}
                                  {item.supplier_name}
                                </a>
                              </div>
                              <div>
                                {/*{data && data.product_id ? data.product_id[index] : ''}*/}
                                <div className="float-right mr-5">
                                  {' '}
                                  Order Number {item.id.substring(0, 8)}
                                </div>
                              </div>
                            </div>
                            <div className="  d-flex  p-3 selectall ">
                              <div className=" mt-2 ">
                                {item.image ? (
                                  <img
                                    src={
                                      item.image
                                        ? process.env.REACT_APP_SAARC_IMAGE_API +
                                          '/' +
                                          item.image.replace('attachments','')
                                        : ''
                                    }
                                    alt="product image"
                                    className="image-product-order-success"
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                              <div>
                                <ol style={{ listStyle: 'none' }}>
                                  <li className="  fontSizeTwelve font-weight-bold margin-botom">
                                    {' '}
                                    Product Name
                                  </li>
                                  <li className=" fontSizeTwelve font-weight-bold margin-botom">
                                    {' '}
                                    Quantity
                                  </li>
                                  <li className=" fontSizeTwelve font-weight-bold margin-botom">
                                    {' '}
                                    Price
                                  </li>
                                  <li className=" fontSizeTwelve font-weight-bold margin-botom">
                                    {' '}
                                    Country
                                  </li>
                                  <li className=" fontSizeTwelve font-weight-bold margin-botom">
                                    {' '}
                                    Date
                                  </li>
                                </ol>
                              </div>
                              <div className="vl ml-3"></div>

                              <div className="">
                                <ol style={{ listStyle: 'none' }}>
                                  <li className="  fontSizeTwelve text-info margin-botom ">
                                    {' '}
                                    <a
                                      className="text-info fontSizeTwelve"
                                      href={
                                        item.product_id
                                          ? process.env.REACT_APP_SAARC_DEFAULT_URL +
                                            '/product-details/' +
                                            item?.product_id
                                          : ''
                                      }
                                      target="_blank"
                                    >
                                      {item?.product_title}
                                    </a>
                                  </li>
                                  <li className=" fontSizeTwelve text-info margin-botom">
                                    {item.qty}
                                  </li>
                                  <li className=" fontSizeTwelve text-info margin-botom">
                                    {' '}
                                    {item?.currency}
                                    {'.'} {item?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </li>
                                  <li className=" fontSizeTwelve text-info margin-botom">
                                    {' '}
                                    {item?.country}
                                  </li>
                                  <li className=" fontSizeTwelve text-info margin-botom ">
                                    {' '}
                                    {item?.updatedAt.substring(0, 10).split('-').reverse().join('-')}
                                  </li>
                                </ol>

                                {/*{item.qty}*/}
                              </div>
                            </div>
                            <div className="row position-relative padding-relative">
                              <div className="col-7 pr-4 ">
                                {' '}
                                {item.user_country === item.country ? (
                                  <div className="  bgInfo pl-3">
                                    <a className="color-white fontSizeTwelve font-italic">
                                      Thank You, your order has been processed. If you want to make
                                      any changes in your order, please contact seller directly on
                                      his Contact Details.{' '}
                                    </a>
                                  </div>
                                ) : (
                                  <div className="bgInfo pl-3">
                                    <a className="color-white fontSizeTwelve font-italic">
                                      SAARC Bazaar does not support international shipments with
                                      cash-on-delivery facility. Please Contact the seller directly
                                      on his Contact details.
                                    </a>
                                  </div>
                                )}
                              </div>
                              <div className="btn-adjustment col-5 d-flex justify-content-end">
                                {' '}
                                {loggedIn ? (
                                  <a
                                    className="btn btn-chat btn-icon p-2 button-width fontSizeTwelve"
                                    onClick={()=> openMessageBoxHandler(item.supplier_name)}
                                  >
                                    Chat Now
                                  </a>


                                ) : (
                                  <a
                                    href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}
                                    className="btn btn-chat btn-icon p-3 button-width fontSizeTwelve"
                                  >
                                    Chat Now
                                  </a>
                                )}
                                {console.log('s dkmc',companiesData)}



                               </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Events Area  */}
        </div>
      </Layout>
    </>
  );
}
