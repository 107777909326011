import React from 'react';

const Ads = () => {
  const bg_image = './images/split/ads.png';
  return (
    <div className="container">
      <div className="mb-5 ads-container " style={{ height: '180px' }}>
        <a href="mailto:info@saarcbazaar.com?subject=Advertisement Information">
          <img
            src={bg_image}
            className="ads-img d-lg-block d-sm-none d-md-none d-xs-none"
            alt="advertisement banner"
          />
        </a>
      </div>
    </div>
  );
};
export default Ads;
