import React, { useEffect, useRef, useState } from 'react';
import SEO from '../../common/SEO';
import DisplayProducts from '../../elements/portfolio/productsDisplay';
import SocialCompany from '../../elements/social/SocialCompany';
import CallToActionCompany from '../../elements/calltoaction/CalltoActionCompany';
import CompanyInformation from './sidebar/CompanyInfo';
import GoogleMapStyle from '../../elements/contact/GoogleMapStyle';
import { BsTelephone, BsWhatsapp } from 'react-icons/bs';
import { SiAdobeacrobatreader } from 'react-icons/si';
import { BiRightTopArrowCircle, BiDollar, BiMap } from 'react-icons/bi';
import ScrollAnimation from 'react-animate-on-scroll';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { COMPANY_STATUS, getUserId, isLoggedIn } from '../../utils';
import { get, put } from '../../utils/api_helper';
import { Link } from 'react-router-dom';
import { AiOutlinePlaySquare, AiOutlineMail, AiFillCalendar } from 'react-icons/ai';
import { CgWebsite } from 'react-icons/cg';
import { MdOutlineCardMembership } from 'react-icons/md';
import { BsBookmarkCheck, BsBookmarkCheckFill, BsShare, BsFillMapFill, BsFillPeopleFill, BsFillPatchQuestionFill, BsFillAwardFill, BsFillBookmarkStarFill, BsFillFilePersonFill, BsFillCartFill } from 'react-icons/bs';
import { Alert, Button, Card, Modal, OverlayTrigger, Popover, Table } from 'react-bootstrap';

// Import Icons
import { GiHamburgerMenu } from 'react-icons/gi';
import { BiMessageAdd } from 'react-icons/bi';
import { GrFacebook, GrGallery, GrTwitter } from 'react-icons/gr';
import { HiTemplate } from 'react-icons/hi';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaCertificate, FaIndustry, FaTrademark } from 'react-icons/fa';

import GalleryOne from '../../elements/gallery/GalleryOne';
import ReactPlayer from 'react-player';
import { FiSend } from 'react-icons/fi';
import * as api from '../../utils/api_helper';
import moment from 'moment';
const CompanyPage = ({companyData}) => {
  const [brochure, setBrochure] = useState();
  const [gallery, setGallery] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [wishlist, setWishlist] = useState();
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);
  const [formData, setFormData] = useState({product_url: '', product_name: '', report_reason: ''});
  const userId = getUserId();
  const iconSize = 30;
  const [des, setDesc] = useState('');
  const loggedIn = isLoggedIn();


  useEffect(() => {
    async function getData() {
      if (companyData.description) {
        setDesc(stateToHTML(convertFromRaw(JSON.parse(companyData.description))));
      }
    }
    getData();
  }, [companyData.description]);


  useEffect(() => {
    // Get brochure
    async function getBrochure() {
      await get('/uploads/' + companyData.brochure)
        .then((resp) => {
          setBrochure(resp.path.substring(11).replace(/\\/g, '/'));
        })
        .catch((err) => {
          console.error('error', err);
        });
    }

    // Get gallery
    async function getGallery() {
      let myPath = '';
      companyData.attachment.split(';').map((item) => {
        if (item !== '') {
          get('/uploads/' + item)
            .then((resp) => {
              myPath = myPath.concat(resp.path.substring(11).replace(/\\/g, '/'));
              setGallery(myPath);
            })
            .catch((err) => {
              console.error('error', err);
            });
        }
      });
    }
    if (companyData?.brochure) {
      getBrochure();
    }
    if (companyData?.attachment) {
      getGallery();
    }
  }, [companyData]);

  useEffect(() => {
    async function getWishlist() {
      await get('/wishlist/user/' + userId)
        .then((resp) => {
          if (resp && resp.length > 0 && resp[0]) {
            setWishlist(resp[0]);
            if (resp[0].companyIds.split(';').find((id) => id === companyData.id)) {
              setAddedToWishlist(true);
            }
          }
        })
        .catch((err) => console.error('Error getting wishlist', err));
    }
    getWishlist();
  }, []);

  // Add to wishlist
  const addToWishlist = async (cId) => {
    await put('/wishlist/company/' + wishlist.id, { companyIds: cId })
      .then((resp) => {
        setAddedToWishlist(true);
      })
      .catch((err) => {
        console.error('Could not add to wishlist', err);
      });
  };

  // Remove from wishlist
  const removeFromWishlist = async () => {
    let updatedIDs = wishlist.companyIds.replace(companyData.id, '').replace(/(^;)/g, '');
    await put('/wishlist/delete/' + wishlist.id, { companyIds: updatedIDs })
      .then(() => {
        setAddedToWishlist(false);
      })
      .catch((err) => console.error('Error updating wishlist', err));
  };

  const handleCloseReport = () => setShowReportModal(false);
  const handleShowReport = () => setShowReportModal(true);

  // Onchange funtion
  const onchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Send report
  const sendReport = (e) => {
    e.preventDefault();
    if (loggedIn) {
      setShowReportModal(false);
      formData.product_name = companyData.name;
      formData.product_url =
        process.env.REACT_APP_SAARC_DEFAULT_URL + '/company-details/' + companyData.id;
      api
        .post('listings/report', formData)
        .then((resp) => {
          setFormData('');
        })
        .catch((e) => {
          console.error(`Failed to send email`, e);
        });
    } else {
      setShowReportModal(false);
      window.location = process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login';
    }
  };

  return (
    <>
      <SEO title={companyData.name} description={des?.substring(0,250) || 'View ' + companyData.name + "'s products on SAARC Bazaar"}/>
      <Modal show={showReportModal} onHide={handleCloseReport}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            If you found this profile or product to be against our Terms and Conditions and think it
            should be removed from SAARC Bazaar then click the Report button.
            <br />
          </p>
          <h5> Please include the reason for reporting</h5>
          <form>
            <input type="text-area" name="report_reason" onChange={onchange} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={(e) => sendReport(e)} className="btn-default btn-primary btn-small">
            <FiSend /> Send Report
          </button>
          <button className="btn-default btn-danger btn-small " onClick={handleCloseReport}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {/*Report Modal Ends Here*/}

      <div className="main-content ">
        <div className="col-12">
          <CallToActionCompany companyHeader={companyData} coverImage={companyData.cover_image} logo={companyData.logo}/>
          {(companyData.status !== COMPANY_STATUS.APPROVED || !companyData.state) && (
            <Alert variant="warning" className="text-center font-weight-bold">{`This Company is ${companyData.state ? 'Enabled' : 'Disabled'} & in ${companyData.status} Status`}</Alert>
          )}

          <div className="col-12">
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true} className="button-group-company text-center mt-5">
              {loggedIn ? (
                <>
                  {companyData?.comp_phone && (
                    <a className="btn-company-page" href={'tel:' + companyData.comp_phone}>
                      <span><BsTelephone /> Call Now</span>
                    </a>
                  )}
                  {companyData?.comp_whatsapp && (
                    <a className="btn-company-page" href={'//wa.me/' + companyData.comp_phone}>
                      <span>
                        <BsWhatsapp /> Whatsapp
                      </span>
                    </a>
                  )}
                </>
              ) : (
                <>
                  {companyData?.comp_phone && (
                    <a className="btn-company-page" href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}>
                      <span><BsWhatsapp /> Whatsapp</span>
                    </a>
                  )}
                </>
              )}

              <a className="btn-company-page" href={companyData?.User?.username ? process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/messages/' + companyData?.User?.username: null} >
                <span><BiMessageAdd /> Instant Messaging</span>
              </a>

              {companyData?.comp_email && (
                <a className="btn-company-page" href={'mailto:' + companyData.comp_email}>
                  <span><AiOutlineMail /> Send an Email</span>
                </a>
              )}

              {companyData.website && (
                <a
                  className="btn-company-page"
                  href={
                    companyData.website.includes('http')
                      ? companyData.website
                      : '//' + companyData.website
                  }
                  target="_blank"
                >
                  <span><CgWebsite /> Website</span>
                </a>
              )}

              {!addedToWishlist ? (
                <Link className="btn-company-page" to="#" onClick={() => addToWishlist(companyData.id)}>
                  <span><BsBookmarkCheck /> Add to Wishlist</span>
                </Link>
              ) : (
                <Link className="btn-company-page" to="#" onClick={() => removeFromWishlist()}>
                  <span><BsBookmarkCheckFill /> Remove</span>
                </Link>
              )}

              <span className="btn-company-page pe-" role="button">
                <OverlayTrigger
                  trigger="click"
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id={`popover-positioned-top`}>
                      <Popover.Title as="h4">{`Share to`}</Popover.Title>
                      <Popover.Content className="p-3">
                        <a
                          href={`https://www.facebook.com/share.php?u=https://saarcbazaar.com/company-details/${companyData.id}`}
                          target="_blank"
                          className="pr-3"
                        >
                          <GrFacebook color="#4267B2" size={iconSize} />
                        </a>
                        <a
                          href={`https://twitter.com/intent/tweet?text=${companyData.name}%20https://saarcbazaar.com/company-details/${companyData.id}`}
                          target="_blank"
                        >
                          <GrTwitter color="#00acee" size={iconSize} />
                        </a>
                        {'     '}
                        <a
                          href={`https://api.whatsapp.com/send/?phone=&text=Hey, check this supplier on SAARC Bazaar. ${companyData.name}%20https://saarcbazaar.com/company-details/${companyData.id}&app_absent=0`}
                          data-action="share/whatsapp/share"
                          target="_blank"
                        >
                          <IoLogoWhatsapp color="#25D366" size={iconSize} />
                        </a>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <span><BsShare /> Share</span>
                </OverlayTrigger>
              </span>
              <a className="btn-company-page cursor-pointer" onClick={() => setShowReportModal(true)}>
                <span><AiOutlineMail /> Report</span>
              </a>
            </ScrollAnimation>
          </div>
        </div>

        {/* Start Company Profile */}
        <div className="mt-5 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                <div>
                  {companyData?.description && (
                    <div className="data-element">
                      <h5 className="mb-3"><span id="profile"><GiHamburgerMenu color="#666666" /> {'   '}Description</span></h5>
                      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                        {showMore ? (
                          <>
                            <div
                              className="company_desc"
                              dangerouslySetInnerHTML={{ __html: des }}
                            />
                          </>
                        ) : (
                          // `${des.substring(0, 250)}`
                          <>
                            <div
                              className="company_desc"
                              dangerouslySetInnerHTML={{ __html: des.substring(0, 250) + '...' }}
                            />
                          </>
                        )}
                        <a className="btn-showmore btn float-right" onClick={() => setShowMore(!showMore)}>
                          {showMore ? 'Show Less' : 'Show more'}
                        </a>
                      </ScrollAnimation>
                    </div>
                  )}

                  {/* Company brochure */}
                  {companyData.brochure && (
                    <>
                      <div className="data-element">
                        <div className="body-font">
                          <h5 className="mb-3">
                            <GiHamburgerMenu color="#666666" /> {'   '}Company Brochure
                          </h5>
                          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                            <Table className="table table-borderless" style={{ 'tableLayout': 'fixed', width: '100%' }} >
                              <tr>
                                <td>
                                  <a href={process.env.REACT_APP_SAARC_API + '/' + brochure} target="_blank" style={{ color: 'black' }} >
                                    <SiAdobeacrobatreader size={25} color="#666666" className="company-icons mr-2"/>
                                    Open in new tab <BiRightTopArrowCircle />
                                  </a>
                                </td>
                              </tr>
                            </Table>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Industry */}
                  {companyData?.industry && (
                    <div className="data-element">
                      <div className="body-font">
                        <h5 className="mb-3"><GiHamburgerMenu color="#666666" /> {'   '}Industry</h5>
                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                          <Table className="table table-borderless" style={{ 'tableLayout': 'fixed', width: '100%' }}>
                            <tbody>
                              <tr>
                                <td><FaIndustry size={25} color="#666666" className="company-icons mr-2" />{companyData.industry}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </ScrollAnimation>
                      </div>
                    </div>
                  )}

                  {/* Type, est, total employees, revenue details */}
                  {(companyData.type || companyData.est || companyData.total_employees || companyData.annual_revenue) && (
                    <div className="data-element">
                      <div className="body-font">
                        <h5 className="mb-3">
                          <GiHamburgerMenu color="#666666" /> {'   '}Company Details
                        </h5>

                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                          <Table className="table table-borderless" style={{ 'tableLayout': 'fixed', width: '100%' }}>
                            <tbody>
                              {companyData?.type && (
                                <tr>
                                  <td> <BsFillPatchQuestionFill size={25} className="company-icons mr-2" color="#666666"/>Business Type:</td>
                                  <td>{companyData.type || 'Not Provided'}</td>
                                </tr>
                              )}
                              {companyData?.est && (
                                <tr>
                                  <td><AiFillCalendar size={25} className="company-icons mr-2" color="#666666" />Date of Establishment:</td>
                                  <td>{moment(companyData.est).format('MMMM YYYY') || 'Not Provided'}</td>
                                </tr>
                              )}
                              {companyData?.total_employees && (
                                <tr>
                                  <td><BsFillPeopleFill size={25} className="company-icons mr-2" color="#666666" />Number of Employees:</td>
                                  <td>{companyData.total_employees || 'Not Provided'}</td>
                                </tr>
                              )}
                              {companyData.annual_revenue && (
                                <tr>
                                  <td><BiDollar size={25} className="company-icons mr-2" color="#666666" />Annual Turnover:</td>
                                  <td>{'$' + companyData.annual_revenue || 'Not Provided'}</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </ScrollAnimation>
                      </div>
                    </div>
                  )}

                  {/* Business market, buyers, main products */}
                  {(companyData?.business_market || companyData?.buyers || companyData?.main_products) && (
                    <div className="data-element">
                      <div className="body-font">
                        <h5 className="mb-3"><GiHamburgerMenu color="#666666" /> {'   '}Trade Capacity</h5>
                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                          <Table style={{ 'tableLayout': 'fixed', width: '100%' }} className="table table-borderless">
                            <tbody>
                              {/* Business Market */}
                              {companyData.business_market && (
                                <tr>
                                  <td><BiMap size={25} color="#666666" className="company-icons mr-2" />Main Business Markets:</td>
                                  <td>{companyData.business_market || 'Not Provided'}</td>
                                </tr>
                              )}

                              {/* Main Buyers */}
                              {companyData.buyers && (
                                <tr>
                                  <td><BsFillFilePersonFill size={25} color="#666666" className="company-icons mr-2"/>Major Buyers:</td>
                                  <td>{companyData.buyers || 'Not Provided'}</td>
                                </tr>
                              )}

                              {/* Main Products */}
                              {companyData.main_products && (
                                <tr>
                                  <td><BsFillCartFill size={25} color="#666666" className="company-icons mr-2"/>Main Products:</td>
                                  <td>{companyData.main_products || 'Not Provided'}</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </ScrollAnimation>
                      </div>
                    </div>
                  )}

                  {/* Certifications, awards, patents, trademarts */}
                  {(companyData.certifications || companyData.awards || companyData.patents || companyData.trademarks) && (
                    <div className="data-element">
                      <div className="body-font">
                        <h5 className="mb-3">
                          <GiHamburgerMenu color="#666666" /> {'   '}Awards and Accomplishments
                        </h5>
                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                          <Table className="table table-borderless" style={{ 'tableLayout': 'fixed', width: '100%' }}>
                            <tbody>
                              {companyData.certifications && (
                                <tr>
                                  <td><FaCertificate size={25} color="#666666" className="company-icons mr-2" />Certifications:</td>
                                  <td>{companyData.certifications || 'Not Provided'}</td>
                                </tr>
                              )}

                              {/* Awards */}
                              {companyData.awards && (
                                <tr>
                                  <td><BsFillAwardFill size={25} color="#666666" className="company-icons mr-2" />Awards:</td>
                                  <td>{companyData.awards || 'Not Provided'}</td>
                                </tr>
                              )}

                              {/* Patents */}
                              {companyData.patents && (
                                <tr>
                                  <td><BsFillBookmarkStarFill size={25} color="#666666" className="company-icons mr-2" />Patents:</td>
                                  <td>{companyData.patents || 'Not Provided'}</td>
                                </tr>
                              )}

                              {/* Trademarks */}
                              {companyData.trademarks ? (
                                <tr>
                                  <td>
                                    <FaTrademark
                                      size={25}
                                      color="#666666"
                                      className="company-icons mr-2"
                                    />
                                    Trademarks:
                                  </td>
                                  <td>{companyData.trademarks || 'Not Provided'}</td>
                                </tr>
                              ) : null}
                            </tbody>
                          </Table>
                        </ScrollAnimation>
                      </div>
                    </div>
                  )}

                  {/* Association links */}
                  {companyData?.association_link && Object.keys(companyData.association_link).length !== 0 && companyData?.association_link != `"{}"` && (
                    <div className="data-element">
                      <div className="body-font">
                        <h5 className="mb-3"><GiHamburgerMenu color="#666666" /> {'   '}Chamber/Association Memeberships</h5>
                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                          <Table className="table table-borderless" style={{ 'tableLayout': 'fixed', width: '100%' }}>
                            <tbody>
                              {companyData?.association_link != `"{}"` && Object.values(JSON.parse(companyData.association_link)).map((item, index) => (
                                <tr key={index}>
                                  <td><MdOutlineCardMembership size={25} color="#666666" className="company-icons mr-2"/>{item || 'Not Provided'}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </ScrollAnimation>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Right sidebar */}
              <div className="col-sm-12 col-md-12 col-lg-5 mt_md--40 mt_sm--40">
                <aside className={'rwt-sidebar sidebar'}>
                  <div className={loggedIn ? 'd-none' : null + ' mb-5 company-page-banner'}>
                    <Card className="register-now-card">
                      <Card.Body>
                        <Card.Text color="#fff">
                          Register now and explore opportunities all over South Asia!
                        </Card.Text>
                        <div className="mt_dec--20 text-center">
                          <a href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}>
                            <Button size="lg" color="green">
                              Sign In
                            </Button>
                          </a>
                          <a href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}>
                            <Button size="lg" color="green">
                              Register Now
                            </Button>
                          </a>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  {/* Social Links */}
                  {(companyData && companyData.facebook) || companyData.twitter || companyData.instagram && (
                    <>
                      <div className="data-element-sidebar rbt-single-widget widget_recent_entries">
                        <div className=" widget_categories ">
                          <h5 className="inner mb-3 "><GiHamburgerMenu color="#666666" /> {'   '}Follow Us</h5>
                          <SocialCompany socialData={companyData} />
                        </div>
                      </div>
                    </>
                  )}

                  {/* End Single Widget  */}
                  {(companyData && companyData.cp_one_name) || companyData.cp_two_name ? (
                    <div className={!loggedIn ? 'd-none' : null + 'rbt-single-widget widget_recent_entries data-element-sidebar'}>
                      <h5 className="inner mb-3"><GiHamburgerMenu color="#666666" /> {'   '}Seller Contact Details</h5>
                      <div className="inner"><CompanyInformation info={companyData} /></div>
                    </div>
                  ) : null}

                  {/* Image Gallery */}
                  {companyData?.attachment && companyData?.attachment !== '' ? (
                    <div className="data-element">
                      <div className="rwt-gallery-area body-font">
                        <div className="container">
                          <div className="row">
                            <h5 className="mb-3">
                              <span id="gallery">
                                <GrGallery color="#666666" /> {'    '}Gallery
                              </span>
                            </h5>
                          </div>
                          <div className="row mt_dec--20 gallery-item">
                            {gallery &&
                              gallery.split(';').map((item) => (
                                <div className="col-lg-3 col-md-12 col-sm-12  mt--30" style={{ padding: '0px 1px 0px 1px' }} key={item.id}>
                                  {item !== '' && (
                                    <GalleryOne galleryItem={{image: process.env.REACT_APP_SAARC_API + '/' + item, popupLink: [process.env.REACT_APP_SAARC_API + '/' + item]}}/>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* Video */}
                  {companyData?.video_URL && companyData?.video_URL !== '' ? (
                    <div className="data-element">
                      <div className="body-font">
                        <div className="container">
                          <div className="row">
                            <h5 className="mb-3"><span id="gallery"><AiOutlinePlaySquare color="#666666" /> {'    '}Video</span></h5>
                          </div>
                          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                            <div className="row mt_dec--20 gallery-item">
                              <div className="col-12 mt--30" style={{ padding: '0px 1px 0px 1px' }}>
                                <ReactPlayer
                                  config={{facebook: {attributes: {'data-width': '400px','data-height': '200px',}}}}
                                  controls={true}
                                  width="100%"
                                  height="200px"
                                  url={companyData.video_URL}
                                />
                              </div>
                            </div>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* Tags */}
                  {companyData?.tags && (
                    <>
                      <div className="data-element">
                        <h5><GiHamburgerMenu color="#666666" /> {'   '}Tags</h5>
                        <ul className="tagcloud" style={{ listStyle: 'none', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
                          {companyData.tags.split(';').map((item) => item !== '' && item !== null && item.length > 1 && (
                            <li><Link to={'/search?option=supplier&s=' + item}>{item}</Link></li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}

                  {/* Start Single Widget  */}
                  {companyData?.company_coordinates ? (
                    <div
                      className={ !loggedIn ? 'd-none' : null + 'rbt-single-widget widget_archive  data-element-sidebar'}
                      style={{ 'background-color': '#fff' }}
                    >
                      <h5 className="inner mb-3"><BsFillMapFill color="#666666" />{'   '}Map</h5>
                      <div className={'inner'}>
                        {companyData?.company_address && (
                          <p>{companyData.company_address}</p>
                        )}
                        <GoogleMapStyle coordinates={companyData.company_coordinates} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={!loggedIn ? 'd-none' : null + 'rbt-single-widget widget_archive  data-element-sidebar' }
                      style={{ 'background-color': '#fff' }}
                    >
                      <h5 className="inner mb-3"><BsFillMapFill color="#666666" />{'   '}Location</h5>
                      <div className={'inner'}>
                        {companyData?.company_address && companyData.company_city && companyData.company_country && (
                          <p>{companyData.company_address + ', ' + companyData.company_city + ', ' + companyData.company_country}</p>
                        )}
                      </div>
                    </div>
                  )}
                  {/* End Single Widget  */}
                </aside>
              </div>

              {/* Display company's other products */}
              <div className="col-lg-12">
                <div className="data-element">
                  <div className="body-font" id="product">
                    <h5 className="mb-3">
                      <HiTemplate color="#666666" size={20} /> {'   '}Company Products
                    </h5>
                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                      <DisplayProducts companyId={companyData.id} col={' col-lg-3 '} />
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Company Profile Area  */}
      </div>
    </>
  );
};

export default CompanyPage;
