import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { AiOutlinePlus } from 'react-icons/all';

function BillingAddressModel({ address, setAddress, openAddressModal, setOpenAddressModal }) {
  const handleClose = () => {
    if (address.supplier_name === '') {
      console.log('Please fill the required form');
    } else {
      setOpenAddressModal(false);
    }
  };
  const handleShow = () => setOpenAddressModal(true);
  const onChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal
        show={openAddressModal}
        onHide={() => setOpenAddressModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <div>
            {' '}
            <p className="m-0 pt-2">Add new Billing Address</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className=" ">
            <form
            // onSubmit={handleSubmit
            >
              <div className="p-4">
                <div className="row">
                  <div className="col">
                    <div className="col-12">
                      <label className="m-0 ">Full Name </label>
                      <input
                        className="p-2 mb-4 input-form"
                        onChange={onChange}
                        name="name"
                        placeholder="Input Full Name"
                        required={true}
                      />
                    </div>
                    <div className="col-12">
                      <label className="m-0">Mobile Number </label>

                      <input
                        className="p-2 mb-4 input-form"
                        onChange={onChange}
                        name="phone"
                        placeholder="Input mobile number"
                      />
                    </div>
                    <div className="col-12">
                      <label className="m-0">Email Address</label>
                      <input
                        className="p-2 mb-4 input-form"
                        onChange={onChange}
                        placeholder="Email"
                        name="email"
                      />
                    </div>
                    
                    <div className="col-12">
                      <h6 onChange={onChange} className="m-0" style={{ fontSize: '14px' }}>
                        City
                      </h6>
                      <input
                        className=" p-2 mb-4 input-form"
                        onChange={onChange}
                        name="city"
                        placeholder="Please choose your city/municipilty"
                      />
                    </div>
                    <div className="col-12">
                      <label className="m-0">Country</label>
                      <input
                        className="p-2 mb-4 input-form"
                        onChange={onChange}
                        name="country"
                        placeholder="Please choose your country"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-12">
                      <label className="m-0">Address</label>
                      <input
                        className="p-2 mb-4 input-form"
                        name="address"
                        onChange={onChange}
                        placeholder="House no. / Building / street / area"
                      />
                    </div>
                    <div className="col-12">
                      <label className="m-0">Zip</label>
                      <input
                        className="p-2 mb-4 input-form"
                        onChange={onChange}
                        placeholder="E.g beside train station"
                        name="zip"
                      />
                    </div>
                    <div className="col-12">
                      <label className="m-0">Landmark(Optional)</label>
                      <input
                        className="p-2 mb-4 input-form"
                        onChange={onChange}
                        placeholder="E.g beside train station"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    className="float-right p-3 pl-5 pr-5 "
                    variant="success"
                    onClick={handleClose}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
          {console.log('addresss ', address)}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BillingAddressModel;
