import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import { get } from '../../utils/api_helper';
import { Modal, Table } from 'react-bootstrap';
import { GoSettings } from 'react-icons/go';
import Select from 'react-select';
import moment from 'moment';
function MyVerticallyCenteredModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Sourcing Request {'  '}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>Product Required:</b>
          {props.row && props.row.product !== undefined ? props.row.product : ''}
          <br />
          <b>Country:</b> {props.row && props.row.country}
          <br />
          <b>Category:</b> {props.row && props.row.category}
          <br />
          <b>Validity</b>: {props.row && props.row.validity}
          <br />
          <b>Description:</b> {props.row && props.row.details}
          <br />
          <b>Attachment:</b>
          {props.row && props.row.attachment ? (
            <a href={process.env.REACT_APP_SAARC_API + '/' + props.row.attachment} target="_blank">
              {' '}
              View
            </a>
          ) : (
            'Not Added'
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <a
          href={
            props.row && props.row.User
              ? process.env.REACT_APP_SAARC_DEFAULT_URL +
                '/users/messages/' +
                props.row.User.username
              : '/'
          }
          className="btn-default btn-primary btn-small"
        >
          Contact Seller
        </a>
        <button className="btn-default btn-danger btn-small" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

const ViewSourcingRequest = (props) => {
  const [datatable, setDatatable] = React.useState({
    columns: [
      { label: 'Product Required', field: 'product', sort: 'asc' },
      { label: 'Country', field: 'country', sort: 'asc' },
      { label: 'Category', field: 'category', sort: 'asc' },
      {
        label: 'Valid',
        field: 'validity',
        sort: 'asc',
        maxWidth: '400px',
        minWidth: '1340px',
      },
      { label: 'Description', field: '', sort: 'asc' },
      { label: 'Attachment', field: '', sort: 'asc' },
    ],
    rows: [],
  });
  const [attachments, setAttachments] = useState();
  const [display, setDisplay] = useState('none');
  const [data, setData] = useState();
  const [filters, setFilters] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [row, setRow] = useState();
  const username = '';

  useEffect(() => {
    if (data) {
      let uniqueCountries = [...new Set(data.map((item) => item.country))];
      for (let i = 0; i < uniqueCountries.length; i++) {
        if (uniqueCountries[i] === null) continue;
        countryOptions.push({
          value: uniqueCountries[i],
          label: uniqueCountries[i],
        });
      }
      let uniqueCategories = [...new Set(data.map((item) => item.category))];
      for (let i = 0; i < uniqueCategories.length; i++) {
        if (uniqueCategories[i] === null) continue;
        category.push({
          value: uniqueCategories[i],
          label: uniqueCategories[i],
        });
      }
    }
  });
  const countryOptions = [];
  const category = [];
  useEffect(() => {
    async function getSourcings() {
      await get('/sourcings')
        .then((resp) => {
          setDatatable((prev) => ({
            ...prev,
            rows: resp.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).reverse(),
          }));
          setData(resp);
          console.log('Response', resp);
        })
        .catch((err) => {
          console.error('Error getting sourcing requests', err);
        });
    }
    getSourcings();
  }, []);
  useEffect(() => {
    async function getAttachments() {
      await get('/uploads')
        .then((resp) => {
          setAttachments(resp);
        })
        .catch((err) => {
          console.error('Error getting uploads', err);
        });
    }
    getAttachments();
  }, []);
  const onChange = (value, action) => {
    if (!value) {
      setFilters({});
    } else {
      setFilters({ ...filters, [action.name]: value.label });
    }
    console.log(filters);
  };

  return (
    <>
      <SEO
        title="Sourcing Request"
        description="Can’t find something? Tell us what you need.
        Post your requirements and let suppliers come to you.
        Sourcing Made Easy"
      />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area mt--30">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <span className="text-center">
                    <h3>Sourcing Requests</h3>
                  </span>
                  <a
                    href="#"
                    className="btn float-right input-button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (display === 'block') {
                        setDisplay('none');
                      } else {
                        setDisplay('block');
                      }
                    }}
                  >
                    Filters <GoSettings size={22} color="blue" />
                  </a>
                </div>
              </div>
              <div className={`d-${display}	d-sm-${display} d-md-${display} d-lg-${display}`}>
                <div className="row ">
                  <div className="col-lg-3 col-md-4 col-sm-12">
                    <Select
                      isClearable
                      name="country"
                      placeholder={'Filter by Country'}
                      onChange={onChange}
                      className="mt-5"
                      options={countryOptions}
                    />
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12">
                    <Select
                      isClearable
                      name="category"
                      placeholder={'Filter by Category'}
                      onChange={onChange}
                      className="mt-5"
                      options={category}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt--20 row--15 mb--40">
                <div className="col-lg-12">
                  {/*<ContactForm formStyle="contact-form-1" query={props.location.query} />*/}
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        {datatable.columns.map((item) => (
                          <th style={{ width: '150px' }}>{item.label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {datatable.rows.map((item) => {
                        if (item.status === 'Approved' && Object.keys(filters).length !== 0) {
                          if (item.country === filters.country) {
                            return (
                              <tr>
                                <MyVerticallyCenteredModal
                                  row={item}
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                />
                                <td>{item.product}</td>
                                <td>{item.country}</td>
                                <td>{item.category}</td>
                                <td>{item.validity}</td>
                                <td>
                                  {item.details
                                    ? item.details.substring(0, 100) + '...'
                                    : 'Not Added'}
                                </td>
                                {item.attachment ? (
                                  <td>
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={process.env.REACT_APP_SAARC_API + '/' + item.attachment}
                                    >
                                      View Pdf
                                    </a>
                                  </td>
                                ) : (
                                  <td>
                                    <p>No attachment</p>
                                  </td>
                                )}
                              </tr>
                            );
                          }
                          if (item.category === filters.category) {
                            return (
                              <tr>
                                <MyVerticallyCenteredModal
                                  row={item}
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                />
                                <td>{item.product}</td>
                                <td>{item.country}</td>
                                <td>{item.category}</td>
                                <td>{item.validity}</td>
                                {item.details
                                  ? item.details.substring(0, 100) + '...'
                                  : 'Not Added'}
                                {item.attachment ? (
                                  <td>
                                    <a
                                      href={process.env.REACT_APP_SAARC_API + '/' + item.attachment}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Pdf
                                    </a>
                                  </td>
                                ) : (
                                  <td>
                                    <p>No attachment</p>
                                  </td>
                                )}
                              </tr>
                            );
                          }
                        } else if (Object.keys(filters).length === 0) {
                          return (
                            <tr
                              onClick={() => {
                                setModalShow(true);
                                setRow(item);
                              }}
                            >
                              <td>{item.product}</td>
                              <td>{item.country}</td>
                              <td>{item.category}</td>
                              <td>{moment(item.validity).format('DD MMM YYYY')}</td>
                              {item.details
                                ? item.details.substring(0, 100) +
                                  (item.details.length > 100 ? '...' : '')
                                : 'Not Added'}
                              {item.attachment ? (
                                <td>
                                  <a
                                    href={process.env.REACT_APP_SAARC_API + '/' + item.attachment}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Pdf
                                  </a>
                                </td>
                              ) : (
                                <td>
                                  <p>No attachment</p>
                                </td>
                              )}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                  <MyVerticallyCenteredModal
                    row={row}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ViewSourcingRequest;
