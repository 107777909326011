import React from 'react';
import { Modal } from 'react-bootstrap';
import { FiSend } from 'react-icons/fi';
import {
  AiFillFacebook,
  AiFillLike,
  AiFillYoutube,
  AiOutlineInstagram,
  BiLike,
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  FaFacebookF,
  FaLinkedinIn,
  HiEmojiSad,
} from 'react-icons/all';
import { Link } from 'react-router-dom';
import { BsTwitter } from 'react-icons/bs';

const HomepageSlider = (props) => {
  const [facebookPopup, setFacebookPopup] = React.useState(props.popup);
  const handleCloseReport = () => {
    setFacebookPopup(!facebookPopup);
  };

  return (
    <div>
      <div className={!facebookPopup ? 'show-follow-this' : 'd-none '}>
        <ul>
          <li>
            <a onClick={handleCloseReport} className="cursor-pointer">
              <BsFillArrowLeftCircleFill color="white" />
            </a>
          </li>
        </ul>
      </div>
      <div className={facebookPopup ? ' follow-this ' : 'd-none'}>
        <ul>
          <li>
            <a onClick={handleCloseReport} className="cursor-pointer">
              <BsFillArrowRightCircleFill color="white" />
            </a>
          </li>
          <li className="facebook-follow">
            <a
              className="cursor-pointer"
              href="https://www.facebook.com/profile.php?id=100083029855856"
              target="_blank"
            >
              <FaFacebookF size={25} color="white" />
            </a>
          </li>
          <li className="twitter-follow">
            <a className="cursor-pointer" href="https://twitter.com/SAARCBazaar" target="_blank">
              <BsTwitter size={25} color="white" />
            </a>
          </li>
          <li className="youtube-follow">
            <a
              className="cursor-pointer"
              href="https://www.youtube.com/channel/UCdnTy56mdogZofDnNhvaxOQ"
              target="_blank"
            >
              <AiFillYoutube size={25} color="white" />
            </a>
          </li>
          <li className="insta-follow">
            <a
              href="https://www.instagram.com/saarc.bazaar"
              target="_blank"
              className="cursor-pointer"
            >
              <AiOutlineInstagram size={25} color="white" />
            </a>
          </li>
          <li className="linkedin-follow">
            <a
              href="https://www.linkedin.com/company/saarc-bazaar/"
              target="_blank"
              className="cursor-pointer"
            >
              <FaLinkedinIn size={25} color="white" />
            </a>
          </li>
        </ul>
      </div>

      {/*<Modal show={facebookPopup} onHide={handleCloseReport} size="md">*/}
      {/*  /!*<Modal.Header closeButton>SAARC Bazaar wants to be your friend!</Modal.Header>*!/*/}
      {/*  <Modal.Body>*/}
      {/*    <div className="text-center">*/}
      {/*      <iframe*/}
      {/*        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSAARC-Bazaar-104279258787117&tabs=timeline&width=340&height=331&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=584684672602961"*/}
      {/*        width="340"*/}
      {/*        height="331"*/}
      {/*        style={{ border: 'none', overflow: 'hidden' }}*/}
      {/*        scrolling="no"*/}
      {/*        frameBorder="0"*/}
      {/*        allowFullScreen="true"*/}
      {/*        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </Modal.Body>*/}
      {/*  <Modal.Footer>*/}
      {/*    <button className="btn-default btn-danger btn-small" onClick={handleCloseReport}>*/}
      {/*      Cancel <HiEmojiSad size="20" color="yellow" />*/}
      {/*    </button>*/}
      {/*  </Modal.Footer>*/}
      {/*</Modal>*/}
    </div>
  );
};
export default HomepageSlider;
