import React, { useState } from 'react';
import { ALL_COUNTRIES, CATEGORIES, Cats, getUserId } from '../../utils';
import * as api from '../../utils/api_helper';
import Select from 'react-select';
import moment from 'moment';
import { Form, Spinner } from 'react-bootstrap';

const Result = () => {
  return (
    <p className="success-message">
      Your Request has been successfully sent. SAARC Bazaar will contact you soon.
    </p>
  );
};

const Error = (props) => {
  return (
    <>
      <p className="text-danger">
        {props.errorMessage ? (
          props.errorMessage
        ) : (
          <>
            You are not Authorized. Please <a href="users/login">Login</a> First.
          </>
        )}
      </p>
    </>
  );
};

function ContactForm(props) {
  const [result, showResult] = useState(false);
  const [formData, setFormData] = useState(props.query ? props.query.data : {});
  const [fileData, setFileData] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiAlert, setApiAlert] = useState(false);

  let userID = getUserId();
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChange = (value, action) => {
    if (!value) {
      setFormData({ ...formData, [action.name]: '' });
    } else {
      setFormData({
        ...formData,
        [action.name]: value.label,
      });
    }
  };
  const onFileChange = (e) => {
    if (e.target.name === 'sourcing') {
      setFileData(e.target.files[0]);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (fileData) {
      const data = new FormData();
      data.append('sourcing', fileData);
      data.append('UserId', userID);
      uploadFiles(data);
    } else {
      formSubmit();
    }
  };
  const postImage = async (url, data) => {
    const response = await fetch(url, {
      method: 'POST',
      body: data,
    });
    return response.json();
  };
  const uploadFiles = (data) => {
    setApiAlert(true);
    postImage(process.env.REACT_APP_SAARC_API + '/uploads/single', data)
      .then((result) => {
        formData.attachment = result.path.substring(11).replace(/\\/g, '/');
        formSubmit();
        setApiAlert(false);
      })
      .catch((err) => {
        console.log(err.message);
        setApiAlert(false);
      });
  };
  const formSubmit = () => {
    formData.UserId = getUserId();
    if (formData.UserId) {
      api
        .post('sourcings/add', formData)
        .then((resp) => {
          console.log('sourcing Created', resp);
          showResult(true);
          setFormData({});
        })
        .catch((error) => {
          console.error('Failed to add sourcing', error);

          if (error.response) {
            setError(true);
            setErrorMessage(error.response.data.message);
            console.log('E', error.response.data.message);
          }
        });
      api
        .post('directory/add', formData)
        .then((resp) => {
          showResult(true);
        })
        .catch((e) => {
          // console.error(`Failed to add directory`, e);
        });
    } else {
      if (!formData.country) {
        setError(true);
        setErrorMessage('Country is required');
      } else {
        console.error('You are not Authorized.');
        setError(true);
      }
    }
  };

  setTimeout(() => {
    showResult(false);
    setError(false);
  }, 20000);

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="form-group ">
          <input
            value={formData.name || ''}
            type="text"
            name="name"
            placeholder="Your Name"
            onChange={onChange}
            required
          />
        </div>

        <div className="form-group ">
          <Select
            required
            placeholder="Destination"
            name="country"
            onChange={handleChange}
            options={ALL_COUNTRIES}
            value={formData.country ? { label: formData.country } : null}
          />
        </div>

        <div className="form-group ">
          <input
            value={formData.phone || ''}
            type="phone"
            name="phone"
            placeholder="Phone Number"
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group ">
          <input
            value={formData.email || ''}
            type="email"
            name="email"
            placeholder="Email"
            onChange={onChange}
            required
          />
        </div>

        <div className="form-group">
          <Select
            placeholder="Select Category"
            name="category"
            onChange={handleChange}
            options={Cats.filter((x) =>
              x.parent_id === null ? ((x.value = x.title), (x.label = x.title)) : null
            )}
            value={formData.category ? { label: formData.category } : null}
          />
        </div>
        <div className="form-group">
          <input
            value={formData.product || ''}
            type="text"
            name="product"
            placeholder="Product(s)/ Service(s) Required"
            onChange={onChange}
            required
          />
        </div>

        <div className="form-group">
          <textarea
            value={formData.details || ''}
            name="details"
            onChange={onChange}
            placeholder="Query"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="validity">Validity</label>
          <input
            value={formData.validity || ''}
            type="date"
            name="validity"
            onChange={onChange}
            required
            min={moment().format('YYYY-MM-DD')}
          />
        </div>

        <div className="form-group mb--20">
          <div className="custom-file">
            <Form.File id="sourcing">
              <Form.File.Label>Upload a file</Form.File.Label>
              <Form.File.Input name="sourcing" id="sourcing" onChange={onFileChange} />
            </Form.File>
          </div>
        </div>

        <div className="form-group text-center">
          <button className="btn-default btn-sm" type="submit" disabled={apiAlert}>
            {apiAlert ? (
              <>
                <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                <span className="visually-hidden"> Loading...</span>
              </>
            ) : (
              'Submit Now'
            )}
          </button>
        </div>
      </Form>
      <div className="form-group">{result ? <Result /> : null}</div>
      <div className="form-group">{error ? <Error errorMessage={errorMessage} /> : null}</div>
    </React.Fragment>
  );
}
export default ContactForm;
