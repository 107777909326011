import DisplayProducts from '../../elements/portfolio/productsDisplay';
import { Link } from 'react-router-dom';
import { containsObject, flatDeep, getParentbyCategory, slugify } from '../../utils';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { BiCategory } from 'react-icons/bi';
import { BsSearch } from 'react-icons/all';
import axios from 'axios';
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    height: '30px',
    padding: '5px',
    'font-size': '12px',
  }),
  control: (provided, state) => ({
    ...provided,
    'background-color': 'white',
    'text-align': 'center',
    'vertical-align': 'center',
    'font-size': '14px',
  }),
  singleValue: (provided, state) => {
    const transition = 'opacity 300ms';
    return { ...provided, transition };
  },
};
const SearchPage = (props) => {
  const [filters, setFilters] = useState({});
  const [text, setText] = useState(props.search || '');
  const [suggestions, setSuggestions] = useState([]);
  const [radioValue, setRadioValue] = useState(props.option === 'products' ? 1 : 2);
  const [checked, setChecked] = useState(props.option === 'products');
  const [display, setDisplay] = useState('none');
  const [parentCat, setParentCat] = useState('');
  const [cat, setCat] = useState('');
  const [bCat, setBCat] = useState('');
  const [cur, setCur] = useState(localStorage.getItem('cur')?.toLowerCase());
  // const loop = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const filteredData = props.listings.filter((item) => {
    if (JSON.stringify(item.title).toLowerCase().includes(props.search.toLowerCase())) {
      return true;
    } else if (
      item.tags &&
      item.tags
        .replace(';', '')
        .replace(/\s/g, '')
        .includes(props.search.toLowerCase().replace(/\s/g, ''))
    ) {
      return true;
    } else if (
      item.category
        .replace(/\s/g, '')
        .toLowerCase()
        .includes(props.search.toLowerCase().replace(/\s/g, ''))
    ) {
      return true;
    }
  });
  const filteredCompanies = props.companies.filter((item) => {
    // return JSON.stringify(item.name).toLowerCase().includes(props.search.toLowerCase());
    if (JSON.stringify(item.name).toLowerCase().includes(props.search.toLowerCase())) {
      return true;
    } else if (
      item.tags &&
      item.tags
        .replace(';', '')
        .replace(/\s/g, '')
        .includes(props.search.toLowerCase().replace(/\s/g, ''))
    ) {
      return true;
    } else if (
      item.industry &&
      item.industry
        .replace(/\s/g, '')
        .toLowerCase()
        .includes(props.search.toLowerCase().replace(/\s/g, ''))
    ) {
      return true;
    }
  });

  const uniCategories = [...new Set(filteredData.map((item) => item.category.split(';')))];
  let myCat = uniCategories.map((item) => {
    return item;
  });

  // Unique Countries
  let uniqueCountries = [
    ...new Set(props.listings.map((item) => item.Company.company_country.toUpperCase())),
  ];

  const countryOptions = [];
  for (let i = 0; i < uniqueCountries.length; i++) {
    if (uniqueCountries[i] === null) continue;
    countryOptions.push({
      value: uniqueCountries[i],
      label: uniqueCountries[i],
    });
  }
  let pricingFilters = [
    { value: 'High to Low', label: 'High to Low' },
    { value: 'Low to High', label: 'Low to High' },
  ];

  //Unique cities
  let uniqueCities = [
    ...new Set(props.listings.map((item) => item.Company.company_city.toUpperCase())),
  ];
  const cityOptions = [];
  for (let i = 0; i < uniqueCities.length; i++) {
    if (uniqueCities[i] === null) continue;
    cityOptions.push({
      value: uniqueCities[i],
      label: uniqueCities[i],
    });
  }

  //Business types
  let uniqueTypes = [...new Set(props.listings.map((item) => item.Company.type))];
  const typeOptions = [];
  for (let i = 0; i < uniqueTypes.length; i++) {
    if (uniqueTypes[i] === null) continue;
    typeOptions.push({
      value: uniqueTypes[i],
      label: uniqueTypes[i],
    });
  }
  //Businesses like women entrepreneur, SME etc.
  let uniqueBusiness = [...new Set(props.listings.map((item) => item.Company.category))];
  const businessOptions = [];
  for (let i = 0; i < uniqueBusiness.length; i++) {
    if (uniqueBusiness[i] === null) continue;
    businessOptions.push({
      value: uniqueBusiness[i],
      label: uniqueBusiness[i],
    });
  }
  const onChangeHandler = (text) => {
    let matches = [];
    if (radioValue === 1) {
      if (text.length > 0) {
        matches = filteredData.filter((data) => {
          const regex = new RegExp(`${text}`, 'gi');
          return data.title.match(regex);
        });
      }
    } else if (radioValue === 2) {
      if (text.length > 0) {
        matches = filteredCompanies.filter((data) => {
          const regex = new RegExp(`${text}`, 'gi');
          return data.name.match(regex);
        });
      }
    }
    setSuggestions(matches);
    setText(text);
  };

  useEffect(() => {
    async function convertCurrency(portfolio) {
      if (portfolio && portfolio.currency) {
        await axios
          .get(
            'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/' +
              portfolio.currency +
              '.json'
          )
          .then((res) => {
            const usdEquivalent = res.data[portfolio.currency][cur] * parseInt(portfolio.price);
            portfolio.price = usdEquivalent.toFixed(2);
            portfolio.currency = cur;
          })
          .catch((err) => {
            console.error('Error fetching currency conversion', err);
          });
      }
    }

    // Convert currency of each item
    props.listings.map((item) => {convertCurrency(item)});
  }, [props.listings]);

  useEffect(() => {
    if (filters.price) {
      if (filters.price === 'High to Low') {
        props.setListings(
          props.listings.slice().sort((a, b) => {
            return b.price - a.price;
          })
        );
      } else if (filters.price === 'Low to High') {
        props.setListings(
          props.listings.slice().sort((a, b) => {
            return a.price - b.price;
          })
        );
      }
    }
    if (filters.moq) {
      if (filters.moq === 'High to Low') {
        props.setListings(
          props.listings.slice().sort((a, b) => {
            return b.moq - a.moq;
          })
        );
      } else if (filters.moq === 'Low to High') {
        props.setListings(
          props.listings.slice().sort((a, b) => {
            return a.moq - b.moq;
          })
        );
      }
    }
  }, [filters]);
  const onSuggestHandler = (text) => {
    setText(text);
    setSuggestions([]);
  };
  const filterTable = () => {
    if (!filteredData) return [];
    const filterKeys = Object.keys(filters);
    return filteredData.filter((item) => {
      return filterKeys.every((key) => {
        if (!filters[key]) return true;
        else if (key === 'category') {
          if (filters[key] === item.category) return true; //product category
        } else if (key === 'pcat') {
          if (filters[key] === item.parent_category) {
            return true;
          } //product parent category
        } else if (key === 'country') {
          if (item.Company.company_country) {
            if (filters[key] === item.Company.company_country) return true; //company's country
          }
        } else if (key === 'city') {
          if (filters[key] === item.Company.company_city) return true; //company's city
        } else if (key === 'type') {
          if (filters[key] === item.Company.type) return true; //company's business type
        } else {
        }
      });
    });
  };

  const companiesFilteredTable = () => {
    if (!filteredCompanies) return [];
    const filterKeys = Object.keys(filters);
    return filteredCompanies.filter((item) => {
      return filterKeys.every((key) => {
        if (!filters[key]) return true;
        else if (key === 'category') {
          if (filters[key] === item.industry) return true; //company category
        } else if (key === 'country') {
          if (filters[key] === item.company_country) return true; //company's country
        } else if (key === 'city') {
          if (filters[key] === item.company_city) return true; //company's city
        } else if (key === 'type') {
          if (filters[key] === item.type) return true; //company's business type
        } else if (key === 'businessCategory') {
          if (filters[key] === item.category) return true; //company's business type e.g. youth entrepreneur
        } else {
        }
      });
    });
  };

  const handleFilters = (title) => {
    if (filters.category === title) {
      setFilters({ ...filters, ['category']: '' });
    } else {
      setFilters({ ...filters, ['category']: title });
    }
  };

  const onChange = (value, action) => {
    if (!value) {
      setFilters({ ...filters, [action.name]: '' });
    } else {
      if (action.name === 'businessCategory') {
      }
      setFilters({ ...filters, [action.name]: value.label });
    }
  };

  let singleCatArray = flatDeep(myCat);
  let categories = [];
  singleCatArray.forEach((cat) => {
    const obj = {
      title: cat,
      slug: slugify(cat),
      count: 1,
    };
    const objIndex = containsObject(obj, categories);
    if (objIndex !== -1) {
      const prevCount = categories[objIndex].count;
      categories[objIndex] = {
        title: cat,
        slug: slugify(cat),
        count: prevCount + 1,
      };
    } else {
      categories.push(obj);
    }
  });

  useEffect(() => {
    setFilters('');
    if (props.cat) {
      handleFilters(props.cat.replaceAll('-', ' ').replaceAll(/\s/g + 'and' + /\s/g, '&'));
      setCat(props.cat.replaceAll('-', ' ').replace(/\s/g + 'and' + /\s/g, '&'));
    } else if (props.parentCat) {
      setParentCat(props.parentCat.replaceAll('-', ' ').replace('and', '&'));
      setFilters({ ...filters, ['pcat']: parentCat });
    } else if (props.bCat) {
      setBCat(props.bCat.replaceAll('-', ' ').replaceAll(/\s/g + 'and' + /\s/g, '&'));
      setFilters({
        ...filters,
        ['businessCategory']: bCat,
      });
    }
  }, [props]);
  useEffect(() => {
    setChecked(!checked);
  }, [radioValue]);
  const clearFilters = () => {
    setFilters({});
  };
  return (
    <>
      {/*Sidebar categories start here*/}
      <div className="main-content container">
        <div className="mt--30">
          <div>
            <div className="row">
              <div
                className={`col-lg-3 mt_dec--30 d-${display} d-sm-${display} d-md-${display} d-lg-block`}
              >
                <aside>
                  <div className="rbt-single-widget widget_categories data-element-sidebar mt-5">
                    <span className="filter-top">
                      <h6>
                        <BiCategory />
                        {'  '}
                        Filters
                      </h6>
                      <Button onClick={clearFilters}>Clear Filters</Button>
                    </span>
                    <div>
                      <Form.Check
                        inline
                        checked={!checked}
                        id="inline-radio-1"
                        name="group1"
                        type="radio"
                        label="Products"
                        onClick={() => setRadioValue(1)}
                      />
                      <Form.Check
                        checked={checked}
                        inline
                        id="inline-radio-2"
                        name="group1"
                        type="radio"
                        label="Suppliers"
                        onClick={() => setRadioValue(2)}
                      />

                      <Select
                        styles={customStyles}
                        isClearable={true}
                        name="country"
                        placeholder={'Filter by Country'}
                        onChange={onChange}
                        className="mt-5"
                        options={countryOptions}
                        value={filters.country ? { label: filters.country } : null}
                      />
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        name="city"
                        placeholder={'Filter by City'}
                        onChange={onChange}
                        className="mt-5"
                        options={cityOptions}
                        value={filters.city ? { label: filters.city } : null}
                      />
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        name="type"
                        placeholder={'Filter by Business Type'}
                        onChange={onChange}
                        className="mt-5"
                        options={typeOptions}
                        value={filters.type ? { label: filters.type } : null}
                      />
                      {radioValue === 1 ? (
                        <>
                          <Select
                            styles={customStyles}
                            isClearable={true}
                            name="price"
                            placeholder={'Filter by Price'}
                            onChange={onChange}
                            className="mt-5"
                            options={pricingFilters}
                          />
                          {/*<Select*/}
                          {/*  styles={customStyles}*/}
                          {/*  isClearable={true}*/}
                          {/*  name="price"*/}
                          {/*  placeholder={'Filter by Price'}*/}
                          {/*  onChange={onChange}*/}
                          {/*  className="mt-5"*/}
                          {/*  value={filters.price ? { label: filters.price } : null}*/}
                          {/*/>*/}
                          <Select
                            styles={customStyles}
                            isClearable={true}
                            name="moq"
                            placeholder={'Filter by Order Quantity'}
                            onChange={onChange}
                            className="mt-5"
                            options={pricingFilters}
                            // value={filters.moq ? { label: filters.moq } : null}
                          />
                        </>
                      ) : (
                        <Select
                          styles={customStyles}
                          isClearable={true}
                          name="businessCategory"
                          placeholder={'Business Category'}
                          onChange={onChange}
                          className="mt-5"
                          options={businessOptions}
                          value={
                            filters.businessCategory ? { label: filters.businessCategory } : null
                          }
                          defaultValue={
                            bCat
                              ? {
                                  value: bCat,
                                  label: bCat,
                                }
                              : ''
                          }
                        />
                      )}
                    </div>
                    {/*<ul className="category-list text-capitalize">*/}
                    {/*  {categories.map((cat) => {*/}
                    {/*    return (*/}
                    {/*      <li key={cat.slug}>*/}
                    {/*        <Link to="" onClick={(e) => e.preventDefault()}>*/}
                    {/*          <span*/}
                    {/*            className={*/}
                    {/*              filters.category === cat.title ? 'left-content' : 'left-content'*/}
                    {/*            }*/}
                    {/*            onClick={() => handleFilters(cat.title)}*/}
                    {/*          >*/}
                    {/*            {cat.title}*/}
                    {/*          </span>*/}
                    {/*        </Link>*/}
                    {/*      </li>*/}
                    {/*    );*/}
                    {/*  })}*/}
                    {/*</ul>*/}
                  </div>
                </aside>
              </div>

              <div className="col-lg-9">
                <div className="mb--20">
                  <form action="/search" onSubmit="/search">
                    <InputGroup>
                      <input
                        name="option"
                        type="hidden"
                        value={radioValue === 1 ? 'products' : 'suppliers'}
                      />
                      <Form.Control
                        style={{ 'font-size': '14px' }}
                        autoComplete="off"
                        type="text"
                        placeholder="Search Here..."
                        name="s"
                        onChange={(e) => onChangeHandler(e.target.value)}
                        value={text}
                      />
                      <Button type="submit" className="btn-info" style={{ padding: '15px' }}>
                        <BsSearch size="18" />
                      </Button>
                    </InputGroup>
                    <div className="inputWithButton " style={{ 'z-index': '1000' }}>
                      {suggestions &&
                        suggestions.slice(0, 6).map((suggestion, i) => (
                          <div
                            className="suggestions cursor-pointer pl-2"
                            key={i}
                            onClick={() => onSuggestHandler(suggestion.title || suggestion.name)}
                          >
                            <span style={{ 'font-size': '12px' }}>
                              {suggestion.title || suggestion.name}
                            </span>
                          </div>
                        ))}
                    </div>
                  </form>

                  {/*<form action={'/search'}>*/}
                  {/*  <div className="inputWithButton">*/}
                  {/*    <input*/}
                  {/*      autoComplete="off"*/}
                  {/*      name="s"*/}
                  {/*      type="text"*/}
                  {/*      onChange={(e) => onChangeHandler(e.target.value)}*/}
                  {/*      value={text}*/}
                  {/*      placeholder="Search Here..."*/}
                  {/*      className="search-page-search"*/}
                  {/*    />*/}
                  {/*    <input*/}
                  {/*      name="option"*/}
                  {/*      type="hidden"*/}
                  {/*      value={radioValue === 1 ? 'products' : 'suppliers'}*/}
                  {/*    />*/}
                  {/*    {suggestions &&*/}
                  {/*      suggestions.slice(0, 6).map((suggestion, i) => (*/}
                  {/*        <div*/}
                  {/*          className="suggestions cursor-pointer"*/}
                  {/*          key={i}*/}
                  {/*          onClick={() => onSuggestHandler(suggestion.title)}*/}
                  {/*        >*/}
                  {/*          {suggestion.title || suggestion.name}*/}
                  {/*        </div>*/}
                  {/*      ))}*/}
                  {/*  </div>*/}
                  {/*</form>*/}
                  {props.cat && cat ? (
                    <p>
                      {getParentbyCategory(cat)}{cat}
                      <Link onClick={() => {setFilters('')}} to={'/search?s='} className="btn cursor-pointer">
                        Clear
                      </Link>
                    </p>
                  ) : (
                    <>
                      {props.parentCat ? (
                        <>
                          {parentCat}
                          <Link onClick={() => {setFilters('')}} to={'/search?s='} className="btn cursor-pointer">
                            Clear
                          </Link>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
                <div>
                  <div className="text-right" style={{ 'z-index': '-1' }}>
                    {/*<ButtonGroup>*/}
                    {/*  {radios.map((radio, idx) => (*/}
                    {/*    <ToggleButton*/}
                    {/*      key={idx}*/}
                    {/*      id={`radio-${idx}`}*/}
                    {/*      type="radio"*/}
                    {/*      size="lg"*/}
                    {/*      variant={idx % 2 ? 'outline-success' : 'outline-success'}*/}
                    {/*      name="radio-filters"*/}
                    {/*      value={radio.value}*/}
                    {/*      checked={radioValue === radio.value}*/}
                    {/*      onChange={(e) => {*/}
                    {/*        setRadioValue(e.currentTarget.value);*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      {radio.name}*/}
                    {/*    </ToggleButton>*/}
                    {/*  ))}*/}
                    {/*</ButtonGroup>*/}
                  </div>
                </div>
                {/*<div className="text-center spinner-custom" role="status">*/}
                {/*  {loop.map((x) => (*/}
                {/*    <span className="sr-only-custom" />*/}
                {/*  ))}*/}
                {/*</div>*/}
                {radioValue === 1 ? (
                  <>
                    <DisplayProducts products={filterTable} col=" col-lg-4 " />
                  </>
                ) : (
                  <DisplayProducts products={companiesFilteredTable} col=" col-lg-4 " />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* End Search Page Area  */}
      </div>
    </>
  );
};

export default SearchPage;
