import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get } from '../../../utils/api_helper';
import { MdVerified } from 'react-icons/md';
import { COMPANY_STATUS } from '../../../utils';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';

const RecommendedProducts = ({ data, StyleVar }) => {
  const [attachments, setAttachments] = useState('');
  const [cur, setCur] = useState(localStorage.getItem('cur'));

  // Convert currency for each product tile
  // Can not convert in one go need to send different calls because the api used here sends response for 1 currency at a time.
  // e.g. I want to get a list of usd conversion, it will give json like usd{pkr: 370, inr: 150} etc.
  async function convertCurrency(product) {
    if (product && product.currency) {
      await axios
        .get(
          'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/' +
          product?.currency +
          '.json'
        )
        .then((res) => {
          const usdEquivalent = res.data[product.currency][cur.toLowerCase()] * parseFloat(product.price);
          product.price = usdEquivalent.toFixed(2);
          product.currency = cur;
        })
        .catch((err) => {
          console.error('Error fetching currency conversion', err);
        });
    }
  }

  useEffect(() => {
    // Call convertcurrency on every rerender
    convertCurrency(data);
  }, [])

  useEffect(() => {
    if (data?.attachment) {
      async function getAttachment() {
        await get('/uploads/' + data.attachment.split(';')[0])
          .then((resp) => {
            setAttachments(resp.path.split(';')[0].substring(11).replace(/\\/g, '/'));
          })
          .catch((err) => {
            console.error('Error getting Attachments', err);
          });
      }
      getAttachment();
    }
  }, []);



  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link to={process.env.PUBLIC_URL + `/product-details/${data.id}`}>
            <figure className={data.featured ? 'img tag tag-featured' : data.is_sale_product ? 'img tag tag-sale' : null} style={{'--saleContent': `"${"-"+data.discount_percentage+"%"}"`}}>
            {data?.attachment && attachments ? (
              <>
                <img
                    className="img-fluid"
                    style={{
                      height: '200px',
                      overflow: 'hidden',
                    }}
                    src={process.env.REACT_APP_SAARC_IMAGE_API + attachments}
                    alt="Product"
                  />
              </>
            ) : (
              <img
                src={process.env.REACT_APP_SAARC_IMAGE_API + '/images/no-preview.png'}
                alt="not found"
                style={{
                  width: '200px',
                  height: '200px',
                  overflow: 'hidden',
                }}
              />
            )}
            </figure>
          </Link>
        </div>
        <div className="content" style={{marginRight: "0 !important"}}>
          <ul className="main-page-products">
            <li className="text-ellipsis text-capitalize">
              <Link className="title" to={process.env.PUBLIC_URL + `/product-details/${data.id}`}>
                {data.title}
              </Link>
            </li>
            <li className="text-ellipsis text-capitalize">
              Supplier: &nbsp;
              <Link to={process.env.PUBLIC_URL + `/company-details/${data.CompanyId}`}>
                {data?.Company?.name}{' '}
              </Link>
            </li>
            <li className="text-ellipsis">
              {data?.Company?.company_country ? (
                <Link to={process.env.PUBLIC_URL + `/product-details/${data.id}`}>
                  <span>Country:&nbsp;{data.Company.company_country}</span>
                </Link>
              ) : (
                <Link to={process.env.PUBLIC_URL + `/product-details/${data.id}`}>
                  <span>Country:&nbsp;Not Provided</span>
                </Link>
              )}
            </li>
            <li className="text-ellipsis">
              <Row>
                <Col lg='6'>
                  {data?.price ?
                    <>
                      {data?.discounted_price && data?.is_sale_product && (
                        <>
                          <Link to={process.env.PUBLIC_URL + `/product-details/${data.id}`} style={{"padding-left": "2px"}}>
                            &nbsp;<span className={'product-price'}>{data?.currency?.toUpperCase()}. {data?.discounted_price}</span>
                          </Link>
                          <br />
                        </>
                      )}
                      <Link to={process.env.PUBLIC_URL + `/product-details/${data.id}`}  style={{"padding-left": "2px"}}>
                        &nbsp;<span className={data.is_sale_product ? 'product-price-strikethrough' : ' product-price'}>{data?.currency?.toUpperCase()}. {data?.price}</span>
                        {/* {data.discount_percentage && <span className='discount-percentage'>-{data?.discount_percentage}%</span>} */}
                      </Link>
                    </>
                    :
                    <Link to={process.env.PUBLIC_URL + `/product-details/${data.id}`} style={{"padding-left": "7px"}}>
                      <span className={'product-price'}>Ask for Price</span>
                    </Link>
                  }
                </Col>
                <Col lg='6' className='d-flex flex-column'>
                  <div className='d-flex justify-content-end mt-auto mr-2'>
                    <ul style={{ listStyleType: "none", display: "flex", justifyContent: "flex-end", padding: '0', margin: '0', flexWrap: 'wrap' }}>
                      {/* If supplier is verified, show verified badge */}
                      {data?.Company?.Chamber?.status === COMPANY_STATUS.APPROVED && (
                        <li>
                          <img height="20" width="20" src="images/icons/verified.png" alt="SAARC Verified Seller" title="Verified Seller" />
                        </li>
                      )}

                      {/* If product has warranty, show warranty badge */}
                      {data?.warranty_type?.trim() !== 'No Warranty' && data?.warranty_type?.trim() !== '' &&
                        <li>
                          <img height="20" width="20" src="images/icons/warranty.png" alt="SAARC products with warranty" title="This product has a warranty" />
                        </li>
                      }

                      {/* If product belongs to a female entrepreneur, show ribbon */}
                      {data?.Company?.category?.split(';').find((category) => category.trim() === 'Women Entrepreneur') &&
                        <li>
                          <img height="20" width="20" src="images/icons/ribbon.png" alt="SAARC Women Entrepreneurs" title="Women Entrepreneur" />
                        </li>
                      }

                      {/* Add more badges here if needed */}
                    </ul>
                  </div>
                </Col>
              </Row>
            </li>
          </ul>
        </div>
        
      </div>
    </div>
  );
};
RecommendedProducts.propTypes = {
  data: PropTypes.object,
};
export default RecommendedProducts;
