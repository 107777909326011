import React, { useContext, useEffect, useState } from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import {AiOutlineInfoCircle, AiOutlinePlus, BiTrash, FaPills, ImCross} from 'react-icons/all';
import Button from 'react-bootstrap/Button';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import { Link, Redirect } from 'react-router-dom';
import { getUserId, ORDER_STATUS } from '../utils';
import * as api from '../utils/api_helper';
import { ButtonToolbar, OverlayTrigger, Spinner } from 'react-bootstrap';
import BillingAddressModel from '../components/addToCartModel/DeliveryDetails';
import ProductListing from '../components/addToCartModel/ProductListing';

export default function CheckOut(props) {
  //---context
  const [cart, setCart] = useState(props.location.state.cart || {});
  const [orderInformation, setOrderInformation] = useState(props.location.state.cart);
  const [order, setOrder] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [orderStatus, setOrderStatus] = useState(false);
  const [orderFailed, setOrderFailed] = useState(false);
  const [address, setAddress] = useState({});
  const [openAddressModal, setOpenModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateProducts, setUpdateProducts] = useState(true);

  const onChange = (e) => {
    setOrderInformation({ ...orderInformation, [e.target.name]: e.target.value });
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    console.log(event.target.checked)
  };

  const deleteProduct = (index) => {
    Object.keys(cart).map((item) => {
      if (
        item !== 'id' &&
        item !== 'createdAt' &&
        item !== 'deletedAt' &&
        item !== 'updatedAt' &&
        item !== 'UserId' &&
        item !== 'state' &&
        item !== 'variation_id'
      ) {
        if (cart && cart[item]) {
          let temp = cart[item].split(';'); //cart[product_id].split(';')
          temp.splice(index, 1);
          cart[item] = temp.join(';');
        }
      }
    });
    updateCart();
  };

  const changeQuantity = async (change, index) => {
    if (change === 'add') {
      if (cart && cart.qty) {
        let qty = cart.qty.split(';'); //save quantities in the form of array
        qty[index] = parseInt(qty[index]) + 1; //increment the specific quantity as of index, also convert it into integer before adding
        cart.qty = qty.join(';'); //Change quantity back to string
        const status = await updateCart(); //update the cart
      }
    } else if (change === 'subtract') {
      if (cart && cart.qty) {
        let qty = cart.qty.split(';'); //save quantities in the form of array
        if (qty[index] === '1') {
          deleteProduct(index);
        } else {
          qty[index] = parseInt(qty[index]) - 1; //decrement the specific quantity as of index, also convert it into integer before adding
          cart.qty = qty.join(';'); //Change quantity back to string
          const status = await updateCart(); //update the cart
        }
      }
    }
  };

  const updateCart = async () => {
    api
      .put('/cart/' + cart.id, cart)
      .then((response) => {
        setRefetch(!refetch);
        return true;
      })
      .catch((error) => {
        console.error('Cart can not be updated', error);
        setRefetch(!refetch);
        return false;
      });
  };
  // useEffect(async () => {
  //   const updatePrice = () => {
  //     setLoader(true);
  //     if (cart && cart.price && cart.qty) {
  //       cart.multiplePrice = '';
  //       cart.status.split(';').map((item, index) => {
  //         console.log('item', item);
  //         if (item === 'true') {
  //           cart.multiplePrice = cart.multiplePrice.concat(
  //             parseInt(cart.qty.split(';')[index]) * parseInt(cart.price.split(';')[index]),
  //             ';'
  //           );
  //         }
  //       });
  //     }
  //     setTimeout(() => setLoader(false), 1000);
  //     console.log('multiple price', cart.multiplePrice);
  //   };
  //   await updatePrice();
  // }, [updateProducts]);

  // useEffect(() => {
  //   if (userId) {
  //     setisLoading(true);
  //     api
  //       .get('/cart/user/' + userId)
  //       .then((response) => {
  //         response[0].status = '';
  //         response[0].multiplePrice = '';
  //         response[0].product_title
  //           .split(';')
  //           .map((item) => (response[0].status = response[0].status.concat(false, ';')));
  //         setCart(response[0]);
  //         setisLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching cart', error);
  //         setisLoading(false);
  //       });
  //   }
  // }, [refetch]);

  const userId = getUserId();
  let keys = [
    'product_id',
    'product_title',
    'qty',
    'price',
    'supplier_id',
    'supplier_name',
    'variation_id',
    'state',
    'currency',
    'image',
    'country',
    'user_country',
  ];

  const submitOrders = async () => {
    setRefresh(true);
    for (let i = 0; i < orderInformation.product_id.split(';').length; i++) {
      console.log('----api');
      orderInformation.product_id = cart.product_id.split(';')[i];
      orderInformation.product_title = cart.product_title.split(';')[i];
      orderInformation.qty = cart.qty?.split(';')[i];
      orderInformation.price = cart.price.split(';')[i];
      orderInformation.currency = cart.currency.split(';')[i];
      orderInformation.supplier_id = cart.supplier_id.split(';')[i];
      orderInformation.user_id = userId;
      orderInformation.supplier_name = cart.supplier_name.split(';')[i];
      orderInformation.image = cart.image?.split(';')[i];
      orderInformation.user_country = orderInformation?.country;
      orderInformation.country = cart?.country?.split(';')[i];
      orderInformation.variation_id = cart?.variation_id?.split(';')[i];
      await api
          .post('/orders/add', orderInformation)
          .then((response) => {
            setOrderStatus(true);
            console.log('response from orderInfo', response.order)
              setOrder(response.order);
            let tempOrder = response.order
            console.log('tempOrder',tempOrder)
            api.post('reviews/add', {
              product_id: tempOrder.product_id,
              order_id: tempOrder.id,
              user_id: tempOrder.user_id
            })
                .then((resp) => {
                  console.log('reviewsState', resp);
                })
                .catch((e) => {
                  console.error(`Failed to save reviews`, e);
                });
              // eslint-disable-next-line no-lone-blocks
              {
                response?.order?.variation_id
                    ? api
                        .get(`variation/${response?.order.variation_id}`)
                        .then((response) => {
                          const quantity = response?.qty.toString();
                          console.log('response', response);
                          if (quantity <= 0) {
                            console.log('add more quantity');
                          } else {
                            const updatedQuanity = quantity - 1;
                            const updatedVariation = {
                              ...response,
                              qty: updatedQuanity,
                            };
                            api
                                .put(`variation/${response?.order.variation_id}`, updatedVariation)
                                .then((updatedVariation) => {
                                  console.log('updatedVariation', updatedVariation);
                                })
                                .catch((error) => {
                                  console.error(error);
                                });
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        })
                    : api
                        .get(`listings/${response?.order.product_id}`)
                        .then((resp) => {
                          const quantity = resp?.qty_simple?.toString();
                          {
                            console.log(resp, 'response');
                            console.log('quantity', quantity, typeof resp?.qty_simple);
                          }
                          if (quantity <= 0) {
                            console.log('Add more Products');
                          } else
                          {
                            const updatedQuantity = quantity - 1;
                            // Modify the variation object to update the qty property
                            const updatedVariation = {
                              ...resp,
                              qty_simple: updatedQuantity,
                            };
                            // Assuming that the API endpoint for updating a variation is `/variations/:variationId`
                            api
                                .put(`/listings/${response?.order.product_id}`, updatedVariation)
                                .then((updatedVariation) => {
                                  console.log('Updated variation:', updatedVariation);
                                  // Do something with the updated variation object
                                })
                                .catch((error) => {
                                  // Handle errors here
                                  console.error(error);
                                });
                          }
                        })
                        .catch((error) => {
                          // Handle errors here
                          console.error(error);
                        });
              }

            emptyCart();
            console.log('Order Placed', response);
          })
          .catch((error) => {
            console.error('Request denied', error);
            setOrderFailed(true);
          });
    }
    setRefresh(false);
  };



  const emptyCart = () => {
    keys.map((item) => (cart[item] = null));
    api
      .put('/cart/' + cart.id, cart)
      .then((response) => console.log('cart cleared successfully'))
      .catch((error) => console.error('Error: ', error));
  };

  console.log('------image');
  console.log(cart);
  return (
    <>
      <BillingAddressModel
        address={orderInformation}
        setAddress={setOrderInformation}
        openAddressModal={openAddressModal}
        setOpenAddressModal={setOpenModal}
      />
      <SEO title="Cart" />
      {orderStatus ? (
        <Redirect
          to={{
            pathname: '/order-success',
            state: {
              id: order?.id,
              order: orderInformation,
            },
          }}
        />
      ) : null}

      {orderFailed ? (
        <Redirect
          to={{
            pathname: '/order-failed',
            state: { id: order.id },
          }}
        />
      ) : null}
      <Layout>
        <>
          <div className="main-content">
            <div className="container-fluid px-5 ">
              <div className="rwt-contact-area mt--30 mb-5">
                <div className="">
                  <div className="row">
                    <div className="col-lg-8 col-sm-12">
                      {console.log('this checkout page data', orderInformation.country)}
                      {orderInformation && orderInformation.name ? (
                        <div>
                          <div className="content-block   cursor-pointer ">
                            <h4 style={{ 'font-size': '17px' }}> Billing Details </h4>
                            <p className="pTags">
                              {' '}
                              Name: {orderInformation.name}{' '}
                              <a
                                className="cursor-pointer pTags"
                                onClick={() => setOpenModal(true)}
                                target="_blank"
                                style={{ color: '#165fcf', fontSize: '10px' }}
                              >
                                change
                              </a>{' '}
                            </p>

                            <p className="pTags">
                              {' '}
                              Address: {orderInformation.address}{' '}
                              <a
                                className="cursor-pointer pTags"
                                onClick={() => setOpenModal(true)}
                                target="_blank"
                                style={{ color: '#165fcf', fontSize: '10px' }}
                              >
                                change
                              </a>{' '}
                            </p>
                            <p className="pTags">
                              {' '}
                              Phone Number: {orderInformation.phone}{' '}
                              <a
                                className="cursor-pointer pTags"
                                onClick={() => setOpenModal(true)}
                                target="_blank"
                                style={{ color: '#165fcf', fontSize: '10px' }}
                              >
                                change
                              </a>{' '}
                            </p>
                            <p className="pTags">
                              {' '}
                              Country: {orderInformation.country}{' '}
                              <a
                                className="cursor-pointer pTags"
                                onClick={() => setOpenModal(true)}
                                target="_blank"
                                style={{ color: '#165fcf', fontSize: '10px' }}
                              >
                                change
                              </a>{' '}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="content-block d-flex justify-content-center cursor-pointer ">
                            <label
                              className="text-secondary cursor-pointer"
                              htmlFor="add-address"
                              onClick={() => setOpenModal(true)}
                            >
                              {' '}
                              Add New Delivery Address
                            </label>
                            <AiOutlinePlus className="mt-2 ml-2" color="gray" id="add-address" />
                          </div>
                        </>
                      )}
                      <div className=" selectAll ">
                        <div className="">
                          <form
                          // onSubmit={handleSubmit }
                          >
                            <SectionTitle textAlign="text-center" />
                            <div>
                              {cart &&
                                cart.product_title &&
                                cart.product_title.split(';').map((item, index) => {
                                  return (
                                    <ProductListing
                                      item={item}
                                      index={index}
                                      deleteProduct={deleteProduct}
                                      changeQuantity={changeQuantity}
                                      updateCart={updateCart}
                                      cart={cart}
                                      checkoutProp={true}
                                    />
                                  );
                                })}
                            </div>
                            <div className="mb-5 text-center form-group"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className=" col-lg-4 col-sm-12 ">
                      {console.log('sppm',cart)}
                      <div className="selectall p-4 pb-2">
                        <div className="row">

                          <h4 className="ml-4 " style={{ 'font-size': '17px' }}>
                            Order Summary
                          </h4>

                        </div>
                        <div className="row justify-content-between">
                          <div className="col-6">
                            <p style={{ marginBottom: 13 }}>Sub-Total</p>
                          </div>
                          <div className="col-6">
                            {loader ? (
                              <Spinner animation="border" />
                            ) : (
                              <>
                                {cart && cart.multiplePrice ? (
                                  <p style={{ marginBottom: 13 }}>
                                    {cart && cart.currency
                                      ? cart.currency.split(';')[0] + '. '
                                      : null}

                                    {cart.multiplePrice
                                      .split(';')
                                      .map(Number)
                                      .reduce((a, b) => a + b)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </p>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row justify-content-between">
                          <div className=" d-flex align-items-center col-6">
                            <p className="deliveryValue ">Delivery</p>
                          </div>
                          <div className=" d-flex align-items-center col-6 ">
                            {/*<p className="m-0">*/}
                            Cash On Delivery
                            {/*</p>*/}
                          </div>
                        </div>

                        <div>
                          <div className="d-flex mt-2">
                            <div ><input type="checkbox" checked={isChecked}
                                         onChange={handleCheckboxChange} className="checkBoxStyle" style={{position: 'relative',opacity:'100' }}/></div>
                            <div ><label className="text-muted label-style" style={{marginTop:'8px' ,marginLeft:'3px',marginBottom:'0'}}>I accept the <a  className="textDecorationUnderLine" target="_blank"  href="https://saarcbazaar.com/terms-and-conditions">terms and conditions</a></label ></div>
                          </div>


                          <Button
                            to={{ pathname: '/checkout', state: { cart } }}
                            className="checkoutButton"
                            style={{ 'margin-top': '0px'}}
                            onClick={() => {
                              if (isChecked) {
                                submitOrders();
                              } else {
                                alert('Please accept the terms and conditions.');
                              }
                            }}
                          >
                            {refresh ? <Spinner animation="border" /> : 'Place Order'}

                          </Button>

                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Conta
          ct Area  */}
          </div>
        </>
      </Layout>
    </>
  );
}
