import React from 'react';
import { AiOutlinePhone, AiOutlineMail, AiFillMail } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { isLoggedIn } from '../../../utils';
import RegisterNow from '../../../elements/registerNow/RegisterNow';
import { MdPhone } from 'react-icons/all';

const CompanyInformation = (props) => {
  return (
    <div className="position-relative">
      <ul className="seller-details no-padding">
        {props.info.cp_one_name ? (
          <>
            <li>
              <div className="">
                {props.info.cp_one_name && props.info.cp_one_name.length !== 0 ? (
                  <p>
                    <BsFillPersonFill
                      size={35}
                      color={'rgba(0,0,0,.6)'}
                      className="company-icons mr-3"
                    />
                    {props.info.cp_one_name}
                    {' - '}
                    {props.info.cp_one_designation}
                  </p>
                ) : null}
                {props.info.cp_one_phone &&
                props.info.cp_one_phone.length !== 0 &&
                props.info.cp_one_dial_code &&
                props.info.cp_one_dial_code.length !== 0 ? (
                  <p>
                    <MdPhone size={35} color={'rgba(0,0,0,.6)'} className="company-icons mr-3" />
                    {'(' + props.info.cp_one_dial_code + ')' + ' ' + props.info.cp_one_phone}
                  </p>
                ) : null}
                {props.info.cp_one_email && props.info.cp_one_email !== 0 ? (
                  <p className="text-ellipsis" title={props.info.cp_one_email}>
                    <AiFillMail size={35} color={'rgba(0,0,0,.6)'} className="company-icons mr-3" />
                    {props.info.cp_one_email}
                  </p>
                ) : null}
              </div>
            </li>
          </>
        ) : null}
        {props.info.cp_two_name ? (
          <>
            <li>
              <div className="data-element-sidebar-product">
                {props.info.cp_two_name && props.info.cp_two_name.length !== 0 ? (
                  <p>
                    <BsFillPersonFill
                      size={35}
                      color={'rgba(0,0,0,.6)'}
                      className="company-icons mr-3"
                    />
                    {props.info.cp_two_name}
                    {' - '}
                    {props.info.cp_two_designation}
                  </p>
                ) : null}
                {props.info.cp_two_phone &&
                props.info.cp_two_phone.length !== 0 &&
                props.info.cp_two_phone &&
                props.info.cp_two_phone.length !== 0 ? (
                  <>
                    <p>
                      <MdPhone size={35} color={'rgba(0,0,0,.6)'} className="company-icons mr-3" />
                      {'(' + props.info.cp_two_dial_code + ')' + ' ' + props.info.cp_two_phone}
                    </p>
                  </>
                ) : null}
                {props.info.cp_two_email && props.info.cp_two_email.length !== 0 ? (
                  <p className="text-ellipsis" title={props.info.cp_two_email}>
                    <AiFillMail size={35} color={'rgba(0,0,0,.6)'} className="company-icons mr-3" />
                    {props.info.cp_two_email}
                  </p>
                ) : null}
              </div>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default CompanyInformation;
