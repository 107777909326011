import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import * as api from '../../utils/api_helper';
import { getUserId } from '../../utils';
import { InputGroup, Spinner } from 'react-bootstrap';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/all';
import { useHistory } from 'react-router-dom';


function CartModel(props) {
  const [cart, setCart] = useState({});
  const [qty, setQty] = useState('1');
  const history = useHistory();

  const [currency, setCurrency] = useState(localStorage.getItem('cur'));

  const userId = getUserId();
  const sendVariationtoCart = () => {
    if(props.variationFilteredData){
      history.push({
        pathname: '/Cart',
        state: { data: props.variationFilteredData}
      });
    }

  };
  const handleClose = () => {
    props.setShowCartModal(false);
  };
  const onQuantityChange = (e) => {
    setQty(e.target.value);
  };
  // const changeQuantity = async (change) => {
  //   if (change === 'add') {
  //     setQty(qty + 1);
  //   } else if (change === 'subtract') {
  //     setQty(parseInt(qty) - 1);
  //   }
  // };

  const changeQuantity = async (change) => {
    if (change === 'add') {
      setQty(String(parseInt(qty, 10) + 1));
    } else if (change === 'subtract') {
      if (qty < 1) {
        console.log('cant be done');
      } else {
        setQty(String(parseInt(qty, 10) - 1));
      }
    }
  };
  const addToCart = () => {
    if (cart) {
      //this is country of user
      cart.country = cart.country ? cart.country.concat(';', props.country) : props.country;
      props.setCartLoading(true);
      cart.product_title = cart.product_title
        ? cart.product_title.concat(';', props.productItem.title)
        : props.productItem.title;
      cart.qty = cart.qty ? cart.qty.concat(';', qty) : qty;
      //this code is written to cater variations
      if(props?.discountedPrices[0]){
        cart.price = cart.price ? cart.price.concat(';', props.discountedPrices[0].toString()) : props.discountedPrices[0].toString();}
       else if (props?.variationFilteredData[0]?.price) {
          cart.price = cart.price ? cart.price.concat(';', props.variationFilteredData[0].price) : props.variationFilteredData[0].price;
        } else {
          cart.price = cart.price ? cart.price.concat(';', props.convertedPrice) : props.convertedPrice;
        }



      // cart.price = cart.price ? cart.price.concat(';', props.convertedPrice) : props.convertedPrice;
      cart.product_id = cart.product_id
        ? cart.product_id.concat(';', props.productItem.id)
        : props.productItem.id;
      cart.supplier_id = cart.supplier_id
        ? cart.supplier_id.concat(';', props.productItem.Company.id)
        : props.productItem.Company.id;
      cart.supplier_name = cart.supplier_name
        ? cart.supplier_name.concat(';', props.productItem.Company.name)
        : props.productItem.Company.name;
      cart.currency = cart.currency ? cart.currency.concat(';', currency) : currency;

      if (props && props?.variationFilteredData && props.variationFilteredData[0]?.attachment ){
        cart.image = cart.image
            ? cart.image.concat(';', props.variationFilteredData[0].attachment?.replace('attachments', '')?.replace(';', ''))
            : props.variationFilteredData[0].attachment?.replace('attachments', '')?.replace(';', '');
      }
     else if (props && props.attachments && props.attachments[0] && props.attachments[0].path) {
        cart.image = cart.image
          ? cart.image.concat(';', props.attachments[0].path.replaceAll(';', ''))
          : props.attachments[0].path.replaceAll(';', '');
      }
     if(props && props?.variationFilteredData && props.variationFilteredData[0]?.id){
       cart.variation_id = cart.variation_id
           ?cart.variation_id.concat(';',props.variationFilteredData[0]?.id)
           :props.variationFilteredData[0]?.id
       {console.log('cart.variation_id',cart?.variation_id)}
     } else {console.log('no variationsFilteredData')}

     sendVariationtoCart()

      api
        .put('/cart/' + cart.id, cart)
        .then((response) => {
          console.log('This is update cart', response);
          setCart(response);
          props.setCartLoading(false);
          props.setShowCartModal(false);
          props.setUpdateCart(props.updateCat + 1);
        })
        .catch((error) => {
          console.log(error);
          props.setCartLoading(false);
          props.setShowCartModal(false);
        });
    }
  };
  useEffect(() => {
    console.log('Fetching Cart');
    if (userId) {
      api
        .get('/cart/user/' + userId)
        .then((response) => {
          setCart(response[0]);
        })
        .catch((error) => console.error('Error fetching cart', error));
    }
  }, []);

  return (
    <>
      <Modal show={props.showCartModal} onHide={handleClose} className="bg-box-shadow{">
        <div className="bg-box-shadow padding-left-right">
          <Modal.Header closeButton>
            <Modal.Title>Add to Cart</Modal.Title>
          </Modal.Header>
          <Modal.Body className="padding-left-right">
            <div className="row">
             <div className="col-lg-4">
                {props.variationFilteredData.length !== 0 ?
                    <img
                        src={
                            process.env.REACT_APP_SAARC_IMAGE_API + '/' +
                            `${props.variationFilteredData.map((variation) => {
                              return variation.attachment?.replace('attachments', '')?.replace(';', '');
                            })}`
                        }
                        width="300"
                        height="300"
                        alt="product-image"
                    />

                    :
                    <>
                      {props.image && props.image[0] && props.image[0].path ? (
                          <img
                              src={`${process.env.REACT_APP_SAARC_IMAGE_API}/${props.image[0].path
                                  .substring(12)
                                  .replace(';', '')}`}
                              width="300"
                              height="300"
                              alt="product-image"
                          />
                      ) : (
                          'No Image Available'
                      )}
                    </>
                }
              </div>
              <div className="col-lg-8">
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label>{props.productItem.title} </Form.Label>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      <strong>Price:</strong>{' '}
                      {props.variationFilteredData.length === 0 ?
                          <>
                          {props.productItem.price && props.convertedPrice
                            ? currency +
                            '. ' +
                            props.convertedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : 'N/A'}
                          </>
                          :
                          <>
                          {props.discountedPrices ? currency + '. ' + props.discountedPrices : currency + '. ' + props.variationFilteredData.map((variationData)=>{ return variationData.price})}

                          </>
                          }
                      {' '}
                    </Form.Label>
                    <Form.Label></Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 ">
                    <Form.Label className="row">
                      <strong className="col-3 mt-2">Quantity</strong>
                      <div className="col-6 ">
                        <AiOutlineMinus
                          class="cursor-pointer"
                          className=" hover-dark mr-3 bg-white"
                          size={28}
                          color=""
                          onClick={() => changeQuantity('subtract')}
                        />{' '}
                        <input
                          type="text"
                          style={{ width: '18px', height: '35px', border: 'none' }}
                          // name={'quantity' + index}
                          min={0}
                          className="pl-0 pr-0"
                          value={qty}
                          // disabled={true}
                        />{' '}
                        <AiOutlinePlus
                          class="cursor-pointer"
                          className=" cursor bg-white  hover-dark"
                          size={28}
                          color=""
                          onClick={() => changeQuantity('add')}
                        />
                      </div>
                    </Form.Label>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-lg" variant="danger" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="btn btn-lg"
              variant="primary"
              onClick={addToCart}
              disabled={!props.productItem.price}
            >
              {props.cartLoading ? <Spinner animation="border" size="sm" /> : 'Add to Cart'}
            </Button>
          </Modal.Footer>
        </div>{' '}
      </Modal>
    </>
  );
}

export default CartModel;
