import React, { useEffect, useState } from 'react';
import ProductDetailsContent from '../elements/portfolio/ProductDetailsContent';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import { get } from '../utils/api_helper';
import { COMPANY_STATUS, getUserId, getUserRole, PRODUCT_STATUS, USER_ROLE } from '../utils';
import Error from './Error';
import * as api from '../utils/api_helper';

const ProductDetails = (props) => {
  const productId = props.match.params.id;
  const preview = props.match.params.preview;
  const [listingData, setListingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [variation1, setVariation1] = useState([]);
  const [variation2, setVariation2] = useState([]);
  const [variationData,setVariationData] = useState([])
    const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const loop = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const userRole = getUserRole();
  const userId = getUserId();
  let url = '';

  useEffect(() => {
    setLoading(true);
    async function getListing() {
      if (!productId) return;
      if (preview === 'preview') {
          url = '/previewlistings/';
      }
      else if (preview === 'update') {
          url = '/templisting/'
      }
      else {
          url = /listings/;
      }
        {console.log('csm',listingData)}
      await get(url + productId)
        .then((resp) => {
          setListingData(resp);
          getVariations(resp.id);
        })
        .catch((err) => {
          console.error('Error product not found', err);
        });
    }
    getListing();
  }, [productId]);

  const getVariations = (listingId) => {
      let variationUrl = '';
      // eslint-disable-next-line no-unused-expressions

      preview === 'update' ?  variationUrl = 'variation/templisting' :   variationUrl = 'variation/listing';
      // eslint-disable-next-line no-unused-expressions
      if(preview!== 'update'){
          api.get(`/variation/templisting/${listingId}`)
              .then((response)=>{
                  if(response.length===0)
                  {
                      variationUrl='variation/templisting'
                  }
                  else {variationUrl='variation/templisting'}
              })
              .catch((error)=>{console.log(error,'error')})
      }
    api
        .get(`/${variationUrl}/${listingId}` )
        .then((response) => {
          let tempVariation1 = [];
          let tempVariation2 = [];
            setVariationData(response)
            response.map((variation) => {
            tempVariation1.push(variation?.variation1);
            tempVariation2.push(variation?.variation2);
          })
          tempVariation1 = [...new Set(tempVariation1)];
          tempVariation2 = [...new Set(tempVariation2)];
          setVariation1(tempVariation1);
          setVariation2(tempVariation2);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching variation', error)
          setLoading(false);
        });
  };
  return loading ? (
    <div className="spinner-custom centered custom-size-spinner" role="status">
      {loop.map((x) => (
        <span className="sr-only-custom" />
      ))}
    </div>
  ) : (
    <>
      {/*{url === 'templisting' ? <></> : null}*/}
      {((listingData.status === PRODUCT_STATUS.APPROVED ||
        listingData.status === PRODUCT_STATUS.PUPDATE) &&
        listingData.state === true &&
        listingData.Company.state === true &&
        listingData.Company.status === COMPANY_STATUS.APPROVED) ||
      (listingData &&
        listingData.User &&
        (userRole === USER_ROLE.ADMIN || userId === listingData.UserId)) ? (
        <>
            {console.log('listingData',listingData)}
            <Layout>
            <ProductDetailsContent
                variationData ={variationData}
              data={listingData}
              productId={productId}
              companyId={listingData.CompanyId}
              username={listingData.User.username}
              preview={preview}
              variation1 = {variation1}
              variation2 = {variation2}
            />
          </Layout>
        </>
      ) : (
        <>
          <SEO title={'404 Not Found'} />

            <Error />
        </>
      )}


    </>
  );
};

export default ProductDetails;
