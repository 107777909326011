import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link, useLocation } from 'react-router-dom';
import { get, put } from '../../utils/api_helper';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import CardComponent from "./cardComponent";
import ScrollAnimation from 'react-animate-on-scroll';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Accordion, Button } from 'react-bootstrap';
import { Emoji } from 'react-emoji';
import { Alert, Card, Col, Modal, OverlayTrigger, Popover, Row, Table, Form, Spinner } from 'react-bootstrap';
import { RiErrorWarningFill } from 'react-icons/ri';
import DisplayProducts from './productsDisplay';
import CompanyInformation from '../../components/blog/sidebar/CompanyInfo';
import { FiSend } from 'react-icons/fi';
import { FaShareAltSquare } from 'react-icons/fa';
import { COMPANY_STATUS, getParentbyCategory, getUserId, isLoggedIn, PRODUCT_STATUS, } from '../../utils';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoLogoWhatsapp } from 'react-icons/io';
import QueryForm from '../queryFrom/QueryForm';
import SectionTitle from '../sectionTitle/SectionTitle';
import moment from 'moment';
import ReactPlayer from 'react-player';
import RecommendedProducts from '../../components/blog/itemProp/RecommendedProducts';
import FAQs from '../accordion/FAQs';
import { GrFacebook, GrTwitter } from 'react-icons/gr';
import { BsHeartFill, BsHeart } from 'react-icons/bs';
import FsLightbox from 'fslightbox-react';
import * as api from '../../utils/api_helper';
import NewMessage from '../NewMessage';
import axios from 'axios';
import ProductQuickLinks from '../../components/blog/sidebar/ProductQuickLinks';
import SEO from '../../common/SEO';
import CartModel from '../../components/addToCartModel/model';
import StarRating from '../../components/StarRating';
import RecommendedProductsTwo from '../../components/blog/itemProp/RecommendedProductsTwo';
import Star from "../../components/Star";
import { CardBody, CardImg, CardSubtitle, CardText, CardTitle } from "reactstrap";
import verifiedMember from '../../_Path_.png'
import WomenEntre from '../../Asset.png'
import Warranty from '../../Warranty.png'

const MyCustomLightbox = (props) => {
  const customSources = [];
  for (let i = 0; i < props.path.length; i++) {
    customSources.push(
      process.env.REACT_APP_SAARC_IMAGE_API +
      '/' +
      props.path[i].path.substring(12).replace(/\\/g, '/').replace(';', '')
    );
  }
  return (
    <FsLightbox toggler={props.toggler} sources={customSources} />
  );
};

const ProductDetailsContent = (props) => {
  const [showCartModel, setShowCartModel] = useState(false);
  const [convertedPrice, setConvertedPrice] = useState(0);
  const [convertedDiscountPrice, setConverteddiscountPrice] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [wishlist, setWishlist] = useState();
  const [selectedVariation1, setSelectedVariation1] = useState('');
  const [selectedVariation2, setSelectedVariation2] = useState('');
  const [currency, setCurrency] = useState(localStorage.getItem('cur'));
  const [showProducts, setShowProducts] = useState(false);
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);
  const { companyId, productId, username } = props;
  const [supplierData, setSupplierData] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [attachments, setAttachments] = useState({});
  const [showMoree, setShowMoree] = useState(false);
  const [des, setDesc] = useState('');
  const [similarProducts, setSimilarProducts] = useState([]);
  const [variationFilteredData, setVariationFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [cart, setCart] = useState(props.cart);
  const [newMessage, setNewMessage] = useState(false);
  const [variationOneBtn, setVariationOneBtn] = useState(false);
  const [variationTwoBtn, setVariationTwoBtn] = useState(false)
  const [discountedPrices, setDiscountedPrices] = useState(0);
  const [reviewsState, setReviewsState] = useState({ product_id: productId, user_name: username });
  const [defaultVideo, setDefaultVideo] = useState('https://www.youtube.com/watch?v=oGL0ryrchOk&t=11s');
  const [formData, setFormData] = useState({ product_url: '', product_name: '', report_reason: '' });
  const [loading, setLoading] = useState(false);
  const iconSize = 20;
  const loggedIn = isLoggedIn();
  const [toggler, setToggler] = useState(false);
  const [path, setPath] = useState('');
  const [updateCart, setUpdateCart] = useState(0);
  const userId = getUserId();
  const [productReview, setProductReview] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [chamberData, setChamberData] = useState([]);
  const [selectedStar, setSelectedStar] = useState(0);
  let dsc = props.data.description;
  let sum = 0;
  let URLparameters = useQuery();

  const handleShowMore = () => {
    setShowMoree(!showMoree);
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (URLparameters.get('id') === 'query') {
      if (document.getElementById('askforpice')) {
        document.getElementById('askforpice').scrollIntoView({ block: 'start' });
      }
    }
  }, []);

  const handleChange = (event) => {
    setReviewsState({
      ...reviewsState,
      [event.target.name]: event.target.value,
    });

  };

  const handleCancel = () => {
    setReviewsState((prevState) => ({ ...prevState, comment: "" }));
  };

  // Handle submit for reviews
  const handleSubmit = async (event) => {
    event.preventDefault();
    let obj = { ...reviewsState, rating: selectedStar, user_name: username, product_id: productId, is_fulfilled: false }

    await api
      .put('reviews/order/' + reviewsState[0]?.order_id, obj)
      .then((resp) => {
        // TODO: Show success later
      })
      .catch((e) => {
        console.error(`Failed to save reviews`, e);
      });
  };

  productReview.forEach((review) => {
    const rating = parseInt(review.rating);
    if (!isNaN(rating)) {
      sum += rating;
    }
  });

  const oneStarCount = productReview.reduce((count, review) => {
    if (review.rating === "1") {
      count++;
    }
    return count;
  }, 0);

  const twoStarCount = productReview.reduce((count, review) => {
    if (review.rating === "2") {
      count++;
    }
    return count;
  }, 0);

  const threeStarCount = productReview.reduce((count, review) => {
    if (review.rating === "3") {
      count++;
    }
    return count;
  }, 0);

  const fourStarCount = productReview.reduce((count, review) => {
    if (review.rating === "4") {
      count++;
    }
    return count;
  }, 0);

  const fiveStarCount = productReview.reduce((count, review) => {
    if (review.rating === "5") {
      count++;
    }
    return count;
  }, 0);

  const handleTabClick = (index) => {
    setActiveTab(index === activeTab ? null : index);
  };

  useEffect(() => {
    api.get('/chamber')
      .then(response => {
        setChamberData(response);

      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    api
      .get('reviews')
      .then(resp => {
        const allFilteredReviews = resp.filter(review => review.product_id === productId);
        setProductReview(allFilteredReviews)
        const filteredReviews = resp.filter(review =>
          review.product_id === productId && review.user_id === userId
        );
        setReviewsState(filteredReviews);
      })
      .catch((e) => {
        console.error(`Failed`, e);
      });
  }, [])

  useEffect(() => {
    const newDiscountedPrices = variationFilteredData.map(variationData => {
      const discountedPrice = variationData.price - (variationData.price * variationData?.discount / 100);
      return discountedPrice;
    });

    setDiscountedPrices(newDiscountedPrices);
  }, [variationFilteredData]);

  useEffect(() => {
    const getCart = () => {
      if (userId) {
        api
          .get('/cart/user/' + userId)
          .then((response) => {
            setCart(response[0]);
          })
          .catch((error) => console.error('Error fetching cart', error));
      }
    };
    getCart();
  }, [updateCart]);

  const handleCloseReport = () => setShowReportModal(false);
  const handleShowReport = () => setShowReportModal(true);

  useEffect(() => {
    // setLoader(false)
    if (selectedVariation1 && selectedVariation2) {
      const filteredData = props.variationData.filter(data => data.variation1 === selectedVariation1 && data.variation2 === selectedVariation2);
      setVariationFilteredData(filteredData)// setLoader(true)
    }
    if (selectedVariation1) {
      const filteredData = props.variationData.filter(data => data.variation1 === selectedVariation1)
      if (filteredData.length === 1) {
        setVariationFilteredData(filteredData)// setLoader(true)
      }
    }
    if (selectedVariation2) {
      const filteredData = props.variationData.filter(data => data.variation2 === selectedVariation2)
      if (filteredData.length === 1) {
        setVariationFilteredData(filteredData)// setLoader(true)
      }
    }
  }, [selectedVariation1, selectedVariation2]);

  useEffect(async () => {
    async function convertCurrency() {
      await axios
        .get(
          'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/' +
          props.data.currency +
          '.json'
        )
        .then((res) => {
          // Convert price to selected currency
          const usdEquivalentPrice = (res.data[props.data.currency][currency.toLowerCase()] * parseFloat(props.data.price)).toFixed(2);
          setConvertedPrice(usdEquivalentPrice);

          // Convert discounted price to selected currency
          const usdEquivalentDiscountedPrice = (res.data[props.data.currency][currency.toLowerCase()] * parseFloat(props.data.discounted_price)).toFixed(2);
          setConverteddiscountPrice(usdEquivalentDiscountedPrice);
        })
        .catch((err) => {
          console.error('Error fetching currency conversion', err);
        });
    }
    if (props.data && props.data.price) {
      if (currency?.toLowerCase() === 'usd') {
        setConvertedPrice(props.data.price + '.00');
        setConverteddiscountPrice(props.data.discounted_price + '.00');
      } else {
        if (props?.data?.currency && props?.data?.currency != null) {
          await convertCurrency();
        }
      }
    }
  }, [props.data.price, currency]);

  useEffect(() => {
    if (variationOneBtn === true) {
      if (variationTwoBtn === true) {
        // console.log('both are true')
      }
    }
    if (variationTwoBtn === true) {
      if (variationOneBtn === true) {
        // console.log('both are true')
      }
    }

  }, [variationOneBtn, variationTwoBtn])

  useEffect(() => {
    async function getSupplier() {
      if (!companyId) return;
      // Get Supplier Data
      await get('/companies/' + companyId)
        .then((resp) => {
          setSupplierData(resp);
        })
        .catch((err) => {
          console.error('error getting company', err);
        });
    }
    async function getUploads() {
      // get path of the attachments
      if (props.data.attachment) {
        const splitAttachments = props.data.attachment?.split(';');
        await get(process.env.REACT_APP_SAARC_IMAGE_API + '/uploads')
          .then((resp) => {
            setAttachments(resp.filter((x) => splitAttachments.includes(x.id)));
          })
          .catch((err) => {
            console.error('error getting uploads', err);
          });
      }
    }

    //used to get uploads.
    getUploads();
    getSupplier();
  }, [companyId, props.data.attachment]);

  useEffect(() => {
    setLoading(true);
    async function getFeaturedProducts() {
      await get('/listings/')
        .then((resp) => {
          setFeaturedProducts(
            resp.filter(
              (product) =>
                product.status === PRODUCT_STATUS.APPROVED &&
                product.state === true &&
                product.Company.state === true &&
                product.Company.status === COMPANY_STATUS.APPROVED
            )
          );
          setLoading(false);
        })
        .catch((err) => {
          console.error('error getting products', err);
          setLoading(false);
        });
    }

    async function getSimilarProducts() {
      if (!productId) return;
      await get('/listings/similar/' + productId)
        .then((resp) => {
          resp.splice(
            resp.findIndex((i) => i.id === productId),
            1
          );
          setSimilarProducts(
            resp.filter(
              (product) =>
                (product.status === PRODUCT_STATUS.APPROVED ||
                  product.status === PRODUCT_STATUS.PUPDATE) &&
                product.state === true &&
                product.Company.state === true &&
                product.Company.status === COMPANY_STATUS.APPROVED
            )
          );
          setLoading(false);
        })
        .catch((err) => {
          console.error('error getting products', err);
          setLoading(false);
        });
    }
    getSimilarProducts();
    getFeaturedProducts();
  }, []);

  const createURL = (path) => {
    return path.substring(12).replace(/\\/g, '/').slice(0, -1);
  };

  const scrollToIdd = (e, name) => {
    e.preventDefault();
    if (name === 'product') {
      if (document.getElementById('product')) {
        document.getElementById('product').scrollIntoView();
      }
    }
  }

  function createArray(length, rating) {
    return Array.from({ length }, (_, index) => {
      return <Star key={index} selected={index < rating} onSelect={() => setSelectedStar(index + 1)} />;
    });
  }

  const createArrays = (length) => [...Array(length)];


  const settings = {
    // slick custom paging for image thumbs
    customPaging: function (i) {
      const createUrl = createURL(attachments[i].path);
      return (
        <a className="slick-thumb">
          <img
            src={`${process.env.REACT_APP_SAARC_IMAGE_API}/${createUrl}`}
            alt={props.data.title}
          />
        </a>
      );
    },
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };
  //Similar products slider settings
  const similar_settings = {
    dots: false,
    infinite: similarProducts.length > 4,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4600,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // function to move screen to relavent id in horizontal buttons
  const scrollToId = (e, name) => {
    e.preventDefault();
    if (name === 'faqs') {
      if (document.getElementById('faqs')) {
        document.getElementById('faqs').scrollIntoView();
      }
    } else if (name === 'query') {
      handleTabClick(0);
      if (document.getElementById('query')) {
        document.getElementById('query').scrollIntoView();
      }
    } else if (name === 'products') {
      if (document.getElementById('products')) document.getElementById('products').scrollIntoView();
    }
    else if (name === 'reviews&rating') {
      if (document.getElementById('reviews&rating')) document.getElementById('reviews&rating').scrollIntoView();
    }
    else if (name === 'youmayalsolike') {
      if (document.getElementById('youmayalsolike')) document.getElementById('youmayalsolike').scrollIntoView();
    }
  };

  useEffect(() => {
    async function getWishlist() {
      await get('/wishlist/user/' + userId)
        //this useEffect gets all the wishlist and then sees if id matches and if it does setAddedToWishlist is trye
        .then((resp) => {
          if (resp && resp[0]) {
            setWishlist(resp[0]);
            if (resp[0].productIds.split(';').find((id) => id === productId)) {
              setAddedToWishlist(true);
            }
          }
        })
        .catch((err) => console.error('Error getting wishlist', err));
    }
    getWishlist();
  }, []);

  //add to wishlist on product page
  const addToWishlist = async (productId) => {
    if (!loggedIn) {
      window.location = process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login';
    } else {
      if (wishlist && !props.preview)
        await put('/wishlist/product/' + wishlist.id, { productIds: productId })
          .then((resp) => {
            setAddedToWishlist(true);
          })
          .catch((err) => {
            console.error('Could not add to wishlist', err);
          });
    }
  };

  const removeFromWishlist = async () => {
    let updatedIDs = wishlist.productIds.replace(productId, '').replace(/(^;)/g, '');
    await put('/wishlist/delete/' + wishlist.id, { productIds: updatedIDs })
      .then(() => {
        setAddedToWishlist(false);
      })
      .catch((err) => console.error('Error updating wishlist', err));
  };
  useEffect(() => {
    async function getData() {
      if (props.data.description) {
        let tempDescription = stateToHTML(convertFromRaw(JSON.parse(dsc)));
        setDesc(tempDescription);
        setShowMore(tempDescription.length < 250);
      }
    }
    getData();
  }, [props.data.description]);
  const sendReport = (e) => {
    e.preventDefault();
    if (loggedIn) {
      setShowReportModal(false);
      formData.product_name = props.data.title;
      formData.product_url =
        process.env.REACT_APP_SAARC_DEFAULT_URL + '/product-details/' + props.data.id;
      api
        .post('listings/report', formData)
        .then((resp) => {
          setFormData('');
          console.log('Email sent');
        })
        .catch((e) => {
          console.error(`Failed to send email`, e);
        });
    } else {
      setShowReportModal(false);
      window.location = process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login';
    }
  };
  const [cartLoading, setCartLoading] = useState(false);

  return (
    <>
      <SEO title={props.data.title} description={des} />
      {/*Start - Modal to confirm reporting product*/}
      {newMessage ? (
        <NewMessage
          companyName={props.data.Company.name}
          username={username}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
        />
      ) : null}
      <Modal show={showReportModal} onHide={handleCloseReport}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            If you found this profile or product to be against our Terms and Conditions and think it
            should be removed from SAARC Bazaar then click the Report button.
            <br />
          </p>
          <h5> Please include the reason for reporting</h5>
          <form>
            <input type="text-area" name="report_reason" onChange={onchange} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={(e) => sendReport(e)} className="btn-default btn-primary btn-small">
            <FiSend /> Send Report
          </button>
          <button className="btn-default btn-danger btn-small " onClick={handleCloseReport}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/*Ends - Modal to confirm reporting product*/}
      <div className="data-element" style={{ padding: '0px' }}>
        <MyCustomLightbox toggler={toggler} path={attachments} />
        <div className="rwt-portfolio-details ">
          <div className="container">
            <div className="inner">
              <div className="details-list">
                <div className="row mt--40 ">
                  <div className="col-lg-9 col-sm-12">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        {(props.data.status !== PRODUCT_STATUS.APPROVED || !props.data.state) &&
                          props.data.status !== PRODUCT_STATUS.PUPDATE && (
                            <Alert variant="warning" className="text-center font-weight-bold" >
                              {`This Product is ${props.data.state ? 'Enabled' : 'Disabled'} & in ${props.data.status
                                } Status`}
                            </Alert>
                          )}

                        {props?.preview && (
                          <Alert variant="warning" className="text-center font-weight-bold">{`Product Preview`}</Alert>
                        )}

                        <div className="d-flex"><h4 className="mb-0">{props.data.title}</h4></div>
                        <p className="product-tagline mb-3">{props.data.tagline}</p>
                        <p className="product-tagline mb-2 color-midgray">
                          {props && props.data ? getParentbyCategory(props.data.category) : ''} >{' '}
                          {props.data.category}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 mt_md--30 mt_sm--30 col-sm-12 ">
                        <div className="content-left">
                          {/* Attachments/Gallery */}
                          <Slider {...settings}>
                            {attachments && Object.keys(attachments).length > 0 &&
                              attachments
                                .sort((a, b) =>
                                  createURL(a.path).split('/').pop() >
                                    createURL(b.path).split('/').pop()
                                    ? 1
                                    : -1
                                ).map((item, index) => {
                                  if (!item) return;
                                  let createUrl = item.path
                                    .substring(12)
                                    .replace(/\\/g, '/')
                                    .slice(0, -1);
                                  return (
                                    <div key={index}>
                                      {variationFilteredData.length !== 0 ?
                                        <img
                                          onClick={() => {
                                            setToggler(!toggler);
                                          }}
                                          className="slide-image "
                                          src={
                                            process.env.REACT_APP_SAARC_IMAGE_API + '/' +
                                            `${variationFilteredData.map((variation) => {
                                              return variation?.attachment?.replace('attachments', '')?.replace(';', '');
                                            })}`
                                          }
                                          alt={props.data.title}
                                        />
                                        :
                                        <img onClick={() => { setToggler(!toggler); setPath(process.env.REACT_APP_SAARC_IMAGE_API + '/' + createUrl) }}
                                          className="slide-image"
                                          src={process.env.REACT_APP_SAARC_IMAGE_API + '/' + createUrl}
                                          alt={props.data.title}
                                        />
                                      }
                                    </div>
                                  )
                                })}


                          </Slider>
                          {props && props.data && props.data.video_URL ? (
                            <ReactPlayer
                              config={{
                                facebook: {
                                  attributes: {
                                    'data-width': '400px',
                                    'data-height': '200px',
                                  },
                                },
                              }}
                              controls={true}
                              width="100%"
                              height="200px"
                              url={props.data.video_URL}
                              className="mb-5 mt-5"
                            />
                          ) : null}
                          {/*{props && props.data && props.data.tags ? (*/}
                          {/*  <>*/}
                          {/*    <h5>*/}
                          {/*      <GiHamburgerMenu color="#666666" /> {'   '}*/}
                          {/*      Tags*/}
                          {/*    </h5>*/}
                          {/*    <ul className="tagcloud">*/}
                          {/*      {props.data.tags*/}
                          {/*        .split(';')*/}
                          {/*        .map((item) =>*/}
                          {/*          item !== '' && item !== null && item.length > 1 ? (*/}
                          {/*            <Link to={'/search?option=products&s=' + item}>{item}</Link>*/}
                          {/*          ) : null*/}
                          {/*        )}*/}
                          {/*    </ul>*/}
                          {/*  </>*/}
                          {/*) : null}*/}
                        </div>

                        {/* {similarProducts && similarProducts.length > 0 && (

                          <SectionTitle
                            id="similar-products"
                            textAlign="text-center"
                            title="You May Also Like"
                            titleLine="title-line"
                          />

                        )} */}
                        {/* <div className="">
                          {similarProducts &&
                            similarProducts.slice(0, 2).map((item) => (
                              <div key={item.id} className="">
                                <RecommendedProductsTwo
                                  StyleVar="background-gray "

                                  data={item}
                                />
                              </div>

                            ))}
                        </div> */}
                      </div>
                      <div className="col mt_md--50 mt_sm--50">
                        <div className="content-right min-height-200 ">
                          <div id="portal" style={{ width: '100%' }}></div>
                          <div className="body-font ">
                            {props && props.data.description ? (
                              <>
                                <h5>
                                  <GiHamburgerMenu color="#666666" /> {'   '}
                                  Product Description
                                </h5>
                                {showMore ? (
                                  <>
                                    <div
                                      className="company_desc mb-5"
                                      dangerouslySetInnerHTML={{ __html: des }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="company_desc mb-5"
                                      dangerouslySetInnerHTML={{
                                        __html: des.substring(0, 250) + '...',
                                      }}
                                    />
                                  </>
                                )}
                                <a
                                  className="btn-showmore  float-right"
                                  onClick={() => setShowMore(!showMore)}
                                >
                                  {des.length > 250 ? (
                                    <>{showMore ? 'Show Less' : 'Show more'}</>
                                  ) : null}
                                </a>
                              </>
                            ) : null}
                            <div className="">

                              <div className="">
                                {props.variation1[0] === null || props.variation1[0] === undefined ? '' : <h5 className=" mr-2 d-inline">Variant</h5>}
                                {props.variationData && [...new Set(props.variationData.map(data => data.variation1))].map((variation1, index) =>
                                (
                                  <button key={index} className={`btn border-1 m-2 text-size-14  square border border-secondary ${selectedVariation1 === variation1 ? 'btn-primary' : 'btn-gray'}`}
                                    onClick={() => {
                                      setVariationOneBtn(true); setSelectedVariation1(variation1);
                                    }}>
                                    {variation1}
                                  </button>
                                ))}
                              </div>
                              <div>
                                {props.variation2[0] === null || props.variation2[0] === undefined ? '' : <h5 className="d-inline mr-2 ">Variant</h5>}

                                {props.variationData && [...new Set(props.variationData.map(data => data.variation2))].map((variation2, index) => (
                                  <>
                                    {variation2 === null ? '' : <> <button key={index} className={`btn border-1 m-2 text-size-14 square border border-secondary ${selectedVariation2 === variation2 ? 'btn-primary' : 'btn-gray'}`}
                                      onClick={() => {
                                        setVariationTwoBtn(true); setSelectedVariation2(variation2);
                                      }}>
                                      {variation2}
                                    </button></>}

                                  </>
                                ))}
                              </div>
                            </div>

                            {props &&
                              props.data &&
                              (props.data.model ||
                                props.data.brand_name ||
                                props.data.warranty_type) ? (
                              <>
                                <div className="mb-5 mt-4">
                                  <h5><GiHamburgerMenu color="#666666" /> {'   '}Product Information</h5>
                                  <Table className="table table-bordered">
                                    <tbody>
                                      {props.data.brand_name ? (
                                        <tr>
                                          <td>Brand Name:</td>
                                          <td>{props.data.brand_name}</td>
                                        </tr>
                                      ) : null}

                                      {props.data.model && (
                                        <tr>
                                          <td>Model:</td>
                                          <td>{props.data.model}</td>
                                        </tr>
                                      )}

                                      {props.data.warranty_type && (
                                        <tr>
                                          <td>{props.data.warranty_type}</td>
                                          <td>{props?.data?.warranty_period}</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </>
                            ) : null}

                            {props &&
                              props.data &&
                              (props.data.origin ||
                                props.data.domestic_delivery_time ||
                                props.data.international_delivery_time ||
                                props.data.moq ||
                                props.data.stock_quantity ||
                                props.data.payment_terms) && (
                                <div className="mb-5 mt-4">
                                  <h5><GiHamburgerMenu color="#666666" /> {'   '}Trade Information</h5>
                                  <Table className="table table-bordered">
                                    <tbody>
                                      {props.data.origin ? (
                                        <tr>
                                          <td>Place of Origin</td>
                                          <td>{props.data.origin}</td>
                                        </tr>
                                      ) : null}

                                      {props.data.moq && (
                                        <tr>
                                          <td>Minimum Order Quantity</td>
                                          <td>{props.data.moq}</td>
                                        </tr>
                                      )}

                                      {props.data.stock_quantity && (
                                        <tr>
                                          <td>Available Stock</td>
                                          <td>{props.data.stock_quantity}</td>
                                        </tr>
                                      )}

                                      {props.data.domestic_delivery_time && (
                                        <tr>
                                          <td>Domestic Delivery Time</td>
                                          <td>{props.data.domestic_delivery_time}</td>
                                        </tr>
                                      )}

                                      {props.data.international_delivery_time && (
                                        <tr>
                                          <td>International Delivery Time</td>
                                          <td>{props.data.international_delivery_time}</td>
                                        </tr>
                                      )}

                                      {props.data.payment_terms && (
                                        <tr>
                                          <td>Payment Terms</td>
                                          <td>{props.data.payment_terms}</td>
                                        </tr>
                                      )}

                                      {props.data.items_per_carton && (
                                        <tr>
                                          <td>Items Per Carton/Package</td>
                                          <td> {props.data.items_per_carton}</td>
                                        </tr>
                                      )}

                                      {props.data.weight && (
                                        <tr>
                                          <td>Weight Per Carton</td>
                                          <td>{props.data.weight_per_carton}</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                            )}

                            {showMoree ?
                              <>
                                <div>{props.data.major_export ? <h5>Export Markets</h5> : ''}
                                  <ul className="ml-3 row">
                                    {props.data.major_export?.split(',').map((majorexport) => (
                                      <li className="pl-0 col-6 text-size-14" key={majorexport}>{majorexport}</li>
                                    ))}
                                  </ul>

                                  {props && props.data && props.data.certification && (
                                    <>
                                      <div className="mb-5">
                                        <h5><GiHamburgerMenu color="#666666" /> {'   '}Awards
                                        </h5>
                                        <Table className="table table-bordered">
                                          <tbody>
                                            <tr>
                                              <td>Certifications</td>
                                              <td>{props.data.certification || 'Not Provided'}</td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>
                                    </>
                                  )}
                                </div>

                                {/* Other information */}
                                {props &&
                                  props.data &&
                                  (props.data.major_export_market ||
                                    props.data.trade_mode ||
                                    props.data.length ||
                                    props.data.width ||
                                    props.data.height ||
                                    props.data.prod_capacity || 
                                    props.data.trade_mode) ? (
                                  <>
                                    <div className="mb-5 mt-4">
                                      <h5><GiHamburgerMenu color="#666666" /> {'   '}Other Information</h5>
                                      <Table className="table table-bordered">
                                        <tbody>
                                          {props.data.major_export_market && (
                                            <tr>
                                              <td>Major Export Market</td>
                                              <td>{props.data.major_export_market}</td>
                                            </tr>
                                          )}

                                          {props.data.trade_mode !== null ? (<tr>
                                            <td>Export/Import Mode</td>
                                            <td>{props.data.trade_mode}</td>
                                          </tr>) : null}

                                          {(props.data.length || props.data.width || props.data.height) !== null && (
                                            <tr>
                                              <td>Dimensions</td>
                                              <td>{`Length: ${props.data.length || 'Not Provided'} Width: ${props.data.width || 'Not Provided'} x Height: ${props.data.height || 'Not Provided'}`}</td>
                                            </tr>
                                          )}

                                          {props.data.prod_capacity ? (
                                            <tr>
                                              <td>Production Capacity Per Month</td>
                                              <td>{props.data.prod_capacity}</td>
                                            </tr>
                                          ) : null}

                                          {props.data.trade_mode ? (
                                            <tr>
                                              <td>Shipping</td>
                                              <td>{props.data.trade_mode}</td>
                                            </tr>
                                          ) : null}

                                        </tbody>
                                      </Table>
                                    </div>
                                  </>
                                ) : null}
                              </>
                              : null}


                            <div className="text-align-end">
                              <button onClick={handleShowMore} className="btn justify">{showMoree ? 'Show Less' : 'Show More'}</button>
                            </div>

                            {/*{props &&*/}
                            {/*props.data &&*/}
                            {/*(props.data.prod_capacity ||*/}
                            {/*  props.data.shipping ||*/}
                            {/*  props.data.total_employees) ? (*/}
                            {/*  <>*/}
                            {/*    <div className="mb-5">*/}
                            {/*      <h5>*/}
                            {/*        <GiHamburgerMenu color="#666666" /> {'   '}*/}
                            {/*        Trade Capacity*/}
                            {/*      </h5>*/}
                            {/*      <ScrollAnimation*/}
                            {/*        animateIn="fadeInUp"*/}
                            {/*        animateOut="fadeInOut"*/}
                            {/*        animateOnce={true}*/}
                            {/*      >*/}
                            {/*        <Table className="table table-bordered">*/}
                            {/*          <tbody></tbody>*/}
                            {/*        </Table>*/}
                            {/*      </ScrollAnimation>*/}
                            {/*    </div>*/}
                            {/*  </>*/}
                            {/*) : null}*/}

                            {/*{props &&*/}
                            {/*props.data &&*/}
                            {/*(props.data.price ||*/}
                            {/*  props.data.moq ||*/}
                            {/*  props.data.lead_time ||*/}
                            {/*  props.data.payment_terms) ? (*/}
                            {/*  <>*/}
                            {/*    <div className="mb-5">*/}
                            {/*      <h5>*/}
                            {/*        <GiHamburgerMenu color="#666666" /> {'   '}*/}
                            {/*        Order Information*/}
                            {/*      </h5>*/}
                            {/*      <ScrollAnimation*/}
                            {/*        animateIn="fadeInUp"*/}
                            {/*        animateOut="fadeInOut"*/}
                            {/*        animateOnce={true}*/}
                            {/*      >*/}
                            {/*        <Table className="table table-bordered">*/}
                            {/*          <tbody>*/}
                            {/*           */}
                            {/*          </tbody>*/}
                            {/*        </Table>*/}
                            {/*      </ScrollAnimation>*/}
                            {/*    </div>*/}
                            {/*  </>*/}
                            {/*) : null}*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'col-lg-3 mt_md--40 mt_sm--40'}>
                    {/*<div*/}
                    {/*  className={*/}
                    {/*    isLoggedIn()*/}
                    {/*      ? 'd-none'*/}
                    {/*      : 'd-block' + 'd-none mt-5 position-absolute container register-now'*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  <RegisterNow />*/}
                    {/*</div>*/}

                    {/*Listing ID is */}
                    <div className="">
                      <div className=" data-element-sidebar-product rbt-single-widget widget_categories">
                        <div className="mt_sm--20">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="text-center ">
                                {addedToWishlist ? (
                                  <>
                                    <button className="btn" onClick={() => removeFromWishlist()}>
                                      <BsHeartFill size={13} color="#DC1A22" /> Remove
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn"
                                      onClick={() => addToWishlist(props.productId)}
                                    >
                                      <BsHeart size={13} color="#DC1A22" /> Add to Wishlist
                                    </button>
                                  </>
                                )}


                                <OverlayTrigger
                                  trigger="click"
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Popover id={`popover-positioned-top`}>
                                      <Popover.Title as="h4">{`Share to`}</Popover.Title>
                                      <Popover.Content className="p-3">
                                        <a
                                          href={`https://www.facebook.com/share.php?u=https://saarcbazaar.com/product-details/${props.data.id}`}
                                          target="_blank"
                                          className="pr-3"
                                        >
                                          <GrFacebook color="#4267B2" size={iconSize} />
                                        </a>
                                        <a
                                          href={`https://twitter.com/intent/tweet?text=${props.data.title}%20https://saarcbazaar.com/product-details/${props.data.id}`}
                                          target="_blank"
                                        >
                                          <GrTwitter color="#00acee" size={iconSize} />
                                        </a>
                                        {'     '}
                                        <a
                                          href={`https://api.whatsapp.com/send/?phone=&text=${props.data.title}%20https://saarcbazaar.com/product-details/${props.data.id}&app_absent=0`}
                                          data-action="share/whatsapp/share"
                                          target="_blank"
                                        >
                                          <IoLogoWhatsapp color="#25D366" size={iconSize} />
                                        </a>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <button className="btn">
                                    <FaShareAltSquare size={15} color="#298DE0" /> Share
                                  </button>
                                </OverlayTrigger>
                                <button className="btn" onClick={handleShowReport}>
                                  {/*<MdReport size={15} color="orange" /> Report*/}
                                  <RiErrorWarningFill size={15} color="orange" /> Report
                                </button>
                              </div>
                              <hr />
                              {props && props.data && props.data.UserId === userId ? (
                                <a
                                  className="btn btn-chat btn-icon bg-danger"
                                  href="/users/listings"
                                >
                                  Edit this Product
                                </a>
                              ) : null}
                              {productReview.length === 0 ? '' :
                                <div className="mb-1">
                                  {isNaN(sum / productReview.length) ? '' : createArray(5, sum / productReview.length)}
                                  {` `}
                                  {isNaN(sum / productReview.length) ? '' : `${sum / productReview.length} Rating`}
                                </div>
                              }

                              <h4 className="mb-3">
                                {variationFilteredData.length === 0 ? (
                                  <>
                                    {props?.data?.discounted_price > 0 && (
                                      <>
                                        <span>
                                          {currency + '. ' + convertedDiscountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        </span>
                                        <br />
                                      </>
                                    )}
                                    <span className={props?.data?.discounted_price > 0 ? 'product-price-strikethrough' : ''}>
                                      {currency + '.  ' + convertedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </span>
                                    {props.data.discount_percentage > 0 && (<span className='discount-percentage'>-%{props.data.discount_percentage}</span>)}
                                  </>
                                ) : (
                                  <>
                                    {variationFilteredData.map((variationData) => {
                                      // eslint-disable-next-line no-unused-expressions
                                      const discountedPrice = variationData.price - (variationData.price * variationData?.discount / 100);
                                      const discountPercentage = variationData?.discount
                                      return (
                                        <>
                                          {/*{variationData.discount ? 'has discount' : 'no discount'}*/}
                                          <span>
                                            {currency + '. ' + discountedPrice}<br />
                                            <span className="text-size-14">Discount</span><span style={{ textDecoration: 'line-through' }} className="text-size-14"> {currency + '. ' + variationData.price}</span>{' '}

                                            {variationData.discount !== 0 && (
                                              <span className="yellow-percent text-size-14"> ({discountPercentage}%)</span>
                                            )}
                                          </span>
                                        </>
                                      );
                                    })}
                                  </>
                                )}
                              </h4>

                              <Button
                                className="btn btn-chat btn-icon"
                                onClick={() => setShowCartModel(true)}
                                disabled={
                                  cart &&
                                  cart.product_id &&
                                  cart.product_id.split(';').includes(props.data.id)
                                }
                              >
                                {cart &&
                                  cart.product_id &&
                                  cart.product_id.split(';').includes(props.data.id)
                                  ? 'Already in Cart' +
                                  ' (' +
                                  cart.qty.split(';')[
                                  cart.product_id.split(';').indexOf(props.data.id)
                                  ] +
                                  ')'
                                  : // cart.product_id
                                  //   .split(';')
                                  //   .indexOf(cart.product_id.split(';').includes(props.data.id))
                                  'Add to Cart'}

                              </Button>

                              {/*<AddToCartModel />*/}
                              <CartModel
                                country={supplierData.company_country}
                                image={attachments}
                                variationFilteredData={variationFilteredData}
                                showCartModal={showCartModel}
                                username={username}
                                setShowCartModal={setShowCartModel}
                                productItem={props.data}
                                discountedPrices={discountedPrices}
                                cartLoading={cartLoading}
                                setCartLoading={setCartLoading}
                                updateCat={updateCart}
                                setUpdateCart={setUpdateCart}
                                attachments={attachments}
                                convertedPrice={convertedPrice}
                              />

                              <Link className="btn btn-chat btn-icon" onClick={() => setShowCartModel(true)}>
                                Buy Now
                              </Link>

                              {/*<Model />*/}
                            </div>
                          </div>
                          {/* End Elements Area  */}
                        </div>
                      </div>

                      {/* Verified Member Badge */}
                      {(props?.data?.Company?.Chamber?.status === COMPANY_STATUS.APPROVED || props?.data?.Company?.category.split(';').find((cat) => cat == 'Women Entrepreneur') || props?.data?.warranty_type?.trim() !== 'No Warranty' && props?.data?.warranty_type?.trim() !== '') && (
                        <div className="data-element-sidebar-product rbt-single-widget widget_categories">
                          <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                            {props?.data?.Company?.Chamber?.status === COMPANY_STATUS.APPROVED && (
                              <div className="col-4">
                                <img src="/images/icons/verified.png" alt="Warranty Badge" title="SAARC Bazaar Verified Supplier" />
                              </div>
                            )}
                            {props?.data?.Company?.category.split(';').find((cat) => cat == 'Women Entrepreneur') && (
                              <div className="col-4">
                                <img src="/images/icons/ribbon.png" alt="Warranty Badge" title="This product belongs to a women entrepreneur" />
                              </div>
                            )}
                            {props?.data?.warranty_type?.trim() !== 'No Warranty' && props?.data?.warranty_type?.trim() !== '' && (
                              <div className="col-4">
                                <img src="/images/icons/warranty.png" alt="Warranty Badge" title="This product has a warranty" />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className={loggedIn ? 'd-none' : null + ' mb-5 company-page-banner'}>
                        <Card className="register-now-card">
                          <Card.Body>
                            <Card.Text color="#fff">
                              Register now and explore opportunities all over South Asia!
                            </Card.Text>
                            {/*check code*/}
                            <div className="mt_dec--20 text-center">
                              <a href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}>
                                <Button size="lg" color="green">
                                  Sign In
                                </Button>
                              </a>
                              <a href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}>
                                <Button size="lg" color="green">
                                  Register Now
                                </Button>
                              </a>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>

                      {supplierData && (supplierData.cp_one_name || supplierData.cp_two_name) ? (
                        <div
                          className={
                            !loggedIn
                              ? 'd-none'
                              : null +
                              'position-absolute ' +
                              ' rbt-single-widget widget_recent_entries'
                          }
                        >
                          <div className="inner">
                            <div className="mt-3">
                              <span className="supplier-name ">
                                <GiHamburgerMenu color="#666666" className="mb-1 mr-2" />
                                <Link to={'/company-details/' + companyId} target="_blank">

                                  <b className="">
                                    <>{supplierData.name}</>
                                  </b>
                                </Link>
                              </span>
                            </div>

                            <CompanyInformation info={supplierData} />
                            <div className="d-flex justify-content-between ">
                              {' '}
                              {loggedIn ? (
                                <a
                                  onClick={() => setNewMessage(true)}
                                  className="btn btn-chat btn-icon text-white mt-3"
                                >
                                  Chat Now
                                </a>
                              ) : (
                                <a
                                  href={process.env.REACT_APP_SAARC_DEFAULT_URL + '/users/login'}
                                  className="btn btn-chat btn-icon text-white mt-3"
                                >
                                  Chat Now
                                </a>
                              )}

                              <a className="btn btn-chat btn-icon ml-1 text-white mt-3  " onClick={(e) => scrollToId(e, 'query')}>
                                Send Query
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className=" data-element-sidebar-product rbt-single-widget widget_recent_entries mt-4">
                        <h5 className="inner mb-3">
                          <GiHamburgerMenu color="#666666" /> {'   '}Quick Links for
                        </h5>
                        <div className="inner">
                          <ProductQuickLinks country={supplierData.company_country} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row product-header d-lg-block d-xl-block d-md-none d-sm-none d-xs-none mt-3">
            <div className="profile-container">
              <div className="profile-menu">
                <div
                  className={
                    props &&
                      props.data &&
                      props.data.faqs &&
                      Object.keys(props.data.faqs).length !== 0 &&
                      Object.values(props.data.faqs)[0] !== '' &&
                      Object.values(props.data.faqs) !== ''
                      ? 'col-2 hover-underline-animation'
                      : ' col-3 ' + ' hover-underline-animation'
                  }
                >
                  <span> Company Other Products</span>

                </div>
                {props &&
                  props.data &&
                  props.data.faqs &&
                  Object.keys(props.data.faqs).length !== 0 &&
                  Object.values(props.data.faqs)[0] !== '' &&
                  Object.values(props.data.faqs) !== '' ? (
                  <div
                    className="col-3 hover-underline-animation  "
                    onClick={() => handleTabClick(4)}
                  >
                    FAQs
                  </div>
                ) : null}

                <div
                  className={
                    props &&
                      props.data &&
                      props.data.faqs &&
                      Object.keys(props.data.faqs).length !== 0 &&
                      Object.values(props.data.faqs)[0] !== '' &&
                      Object.values(props.data.faqs) !== ''
                      ? 'col-2 hover-underline-animation'
                      : ' col-3 ' + ' hover-underline-animation'
                  }
                >
                  <span onClick={() => handleTabClick(0)} id="query">Query Form</span>

                </div>

                <div
                  className="col-3 hover-underline-animation">
                  <span onClick={() => handleTabClick(5)}>Ratings & Reviews</span>
                </div>
                <div
                  className="col-3 hover-underline-animation">
                  <span onClick={() => handleTabClick(3)} >You May Also Like</span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="rn-blog-area mt--30">
          <div className="container">
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                {props &&
                  props.data &&
                  props.data.faqs &&
                  Object.keys(props.data.faqs).length !== 0 &&
                  Object.values(props.data.faqs)[0] !== '' &&
                  Object.values(props.data.faqs) !== '' ? (
                  // <AdvanceTabOne  faqs={props.data.faqs} />
                  <div className="data-element-sidebar-product">
                    <span id="faqs">
                      <SectionTitle textAlign="text-center" radiusRounded="" subtitle="Accordion" title="Frequently Asked Questions" description="" />
                      <FAQs faqs={props.data.faqs} />{' '}
                    </span>
                  </div>
                ) : null}

                <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
                  <div className="data-element-sidebar-product" id="askforpice">
                    <div className="mt-5" id="reviews&rating">
                      <span>
                        <SectionTitle textAlign="text-center" title="Query Form" />
                      </span>
                    </div>
                    <QueryForm company={supplierData} productData={props.data} preview={props.preview} />
                  </div>
                </div>

                <div>
                  <div className="data-element-sidebar-product" id="">
                    <div className="" id="">
                      <span>
                        <SectionTitle textAlign="text-center" title="" />
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-1"></div>
                      <div className="col-6">
                        <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '0px' }}>{isNaN(sum / productReview.length) ? 'No Rating' : sum / productReview.length} <span style={{ fontSize: '14px', fontWeight: 'normal', display: 'inline' }}>/5</span><Star selected={5} /><Star selected={5} /><Star selected={5} /><Star selected={5} /></p>
                        <p>Reviewed by {productReview.length} users</p>
                      </div>
                      <div className="col-4">
                        <div style={{ display: 'flex', alignItems: 'center', height: '21px' }}>
                          <p style={{ marginRight: '10px', fontSize: '12px' }}>5 stars</p>
                          <ProgressBar now={fiveStarCount / productReview.length * 100} variant="success" style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }} />
                          <p style={{ marginLeft: '10px', fontSize: '12px' }}>{isNaN(fiveStarCount / productReview.length * 100) ? '0' : `${fiveStarCount / productReview.length * 100}`
                          }%</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', height: '21px' }}>
                          <p style={{ marginRight: '10px', fontSize: '12px' }}>4 stars</p>
                          <ProgressBar now={fourStarCount / productReview.length * 100} variant="success" style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }} />
                          <p style={{ marginLeft: '10px', fontSize: '12px' }}>{isNaN(fourStarCount / productReview.length * 100) ? '0' : `${fourStarCount / productReview.length * 100}`
                          }%</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', height: '21px' }}>
                          <p style={{ marginRight: '10px', fontSize: '12px' }}>3 stars</p>
                          <ProgressBar now={threeStarCount / productReview.length * 100} variant="success" style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }} />
                          <p style={{ marginLeft: '10px', fontSize: '12px' }}>{isNaN(threeStarCount / productReview.length * 100) ? '0' : `${threeStarCount / productReview.length * 100}`
                          }%</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', height: '21px' }}>
                          <p style={{ marginRight: '10px', fontSize: '12px' }}>2 stars</p>
                          <ProgressBar now={twoStarCount / productReview.length * 100} variant="success" style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }} />
                          <p style={{ marginLeft: '10px', fontSize: '12px' }}>{isNaN(twoStarCount / productReview.length * 100) ? '0' : `${twoStarCount / productReview.length * 100}`
                          }%</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', height: '21px' }}>
                          <p style={{ marginRight: '10px', fontSize: '12px' }}>1 star{` `}</p>
                          <ProgressBar now={oneStarCount / productReview.length * 100} variant="success" style={{ backgroundColor: 'lightgray', flex: 1, marginBottom: '30px' }} />
                          <p style={{ marginLeft: '10px', fontSize: '12px' }}>{isNaN(oneStarCount / productReview.length * 100) ? '0' : `${oneStarCount / productReview.length * 100}`
                          }%</p>
                        </div>

                      </div>
                    </div>
                    <div>
                      <h6 className="mt-2">Comments</h6>
                      {reviewsState && reviewsState[0]?.is_fulfilled === true ?
                        <>
                          <div className="d-flex">
                            <div className="dropdown">
                              {createArrays(5).map((n, i) => (
                                <Star key={i} selected={selectedStar > i} onSelect={() => setSelectedStar(i + 1)} />
                              ))}
                              <div className="dropdown-content">
                                <Popover id="popover-basic"></Popover>
                              </div>
                            </div>

                            <p className="mt-1 cursor-pointer" style={{ fontSize: '14px', marginBottom: '10px' }} >
                              ({selectedStar} Rating)
                            </p>
                          </div>

                          <input
                            placeholder='Write your review here'
                            className="mb-2"
                            name='comment'
                            value={reviewsState.comment}
                            onChange={handleChange}
                          />
                          <button className="btn-primary p-2 mb-5" type="button" onClick={handleCancel}>
                            Cancel
                          </button>
                          <button className='btn-add' onClick={handleSubmit}>
                            Send
                          </button>
                        </>

                        :
                        <></>

                      }


                    </div>
                    <div>
                      {productReview.map((item) => (
                        <Card key={item.id}>
                          <CardBody>
                            <div className="reviews-top">
                              <div className="user-details d-flex align-items-center">
                                <CardImg
                                  style={{ borderRadius: '50%', width: '50px', height: '50px' }}
                                  src={`https://ui-avatars.com/api/?name=${item.user_name?.charAt(0)?.toUpperCase()}&size=50`}
                                  alt=""
                                />
                                <h6 className="mb-2 text-muted ml-2 mr-3">{item.user_name}</h6>
                                {createArray(5, item.rating)}
                              </div>
                              <div className="reviews-body">
                                <CardText>
                                  {item.comment ||
                                    ''}
                                </CardText>
                              </div>
                              <CardText>
                                <small className="text-muted text-bold">
                                  {item.createdAt?.slice(0, 10) || ''}
                                </small>
                              </CardText>
                            </div>
                          </CardBody>
                        </Card>
                      ))
                      }
                    </div>
                  </div>
                </div>
                {showProducts &&
                  (<div className="data-element-sidebar-product">
                    <span id="products">
                      <SectionTitle
                        id="products"
                        textAlign="text-center"
                        title="Company Other Products"
                      />
                    </span>
                    <DisplayProducts columns={3} companyId={supplierData.id} />
                  </div>
                  )}
              </Col>
            </Row>

            <div className="col-lg-12 pt--30">
              {activeTab === 3 &&
                <>
                  {similarProducts && similarProducts.length > 0 && (
                    <div id="youmayalsolike"> <SectionTitle
                      id="similar-products"
                      textAlign="text-center"
                      title="You May Also Like"
                      titleLine="title-line"
                    /></div>

                  )}
                  <Slider {...similar_settings} className="mb-5 ml_dev-15">
                    {similarProducts &&
                      similarProducts.map((item) => (
                        <div key={item.id} className="col-12">
                          <RecommendedProducts StyleVar="box-card-style-default" data={item} />
                        </div>
                      ))}
                  </Slider>
                </>}

            </div>
            <div className="col-lg-12 pt--30">
              {featuredProducts && featuredProducts.length > 0 && (
                <SectionTitle
                  id="similar-products"
                  textAlign="text-center"
                  title="Featured Products"
                  titleLine="title-line"
                />
              )}
              <Slider {...similar_settings} className="mb-5 ml_dev-15">
                {featuredProducts &&
                  featuredProducts.map((item) => (
                    <div key={item.id} className="col-12">
                      <RecommendedProducts StyleVar="box-card-style-default" data={item} />
                    </div>
                  ))}
              </Slider>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsContent;
